import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import { useTranslation } from 'next-i18next';
import { useAppContext } from '../../../src/store/AppContext';
import displayPrice from '../../../src/utils/displayPrice';
import useStocks from '../../../src/hooks/useStocks';
import usePrice from '../../../src/hooks/usePrice';

// Icons
import CloseIcon from '../../../public/icons/cross.svg';

// Components
import PlayerEntry from './PlayerEntry/PlayerEntry';
import PlayersCounter from './PlayersCounter/PlayersCounter';
import Hosting from './Hosting/Hosting';
import NextButton from './NextButton/NextButton';
import CartInformations from './CartInformations/CartInformations';

import styles from './Cart.module.scss';

function Cart({
  reverseBackground, setCartIsOpened, isOutsideTunnel,
}) {
  const { t } = useTranslation();
  const [{ currentPlayer, players, hosting }] = useAppContext();

  const { getStockExtraByPlayer } = useStocks();
  const { getTotalCart } = usePrice();

  const cartContent = useRef('cartContent');

  const [needScroll, setNeedScroll] = useState(false);
  const [productsStockByPlayer, setProductsStockByPlayer] = useState(null);

  const handleScrollCheck = () => {
    if (cartContent?.current) {
      setNeedScroll(cartContent.current.scrollHeight > cartContent.current.clientHeight);
    }
  };

  useEffect(() => {
    handleScrollCheck();

    window.addEventListener('resize', handleScrollCheck, true);
    return () => window.removeEventListener('resize', handleScrollCheck, true);
  }, []);

  useEffect(() => {
    if (!productsStockByPlayer && players?.length) {
      getStockExtraByPlayer(setProductsStockByPlayer, players);
    }
  }, [players]);

  return (
    <section ref={cartContent} className={`${styles.cart_wrapper} ${reverseBackground ? styles.reverse : ''}`}>
      <div className={`${styles.sticky_top} flex a-center j-spb`}>
        <h2 className="fs-20 text-c-primary fw-700">{t('cart.title')}</h2>
        <PlayersCounter className={`${styles.counter} flex a-center ${setCartIsOpened ? 'm-l-20' : ''}`} />
        {setCartIsOpened ? (
          <button type="button" onClick={() => setCartIsOpened(false)} className="m-l-auto c-pointer">
            <CloseIcon width={16} height={16} />
          </button>
        ) : null}
      </div>

      {players?.length ? (
        <>
          <div className="m-b-10 p-r-40 p-l-40">
            {players.map((player) => (
              <PlayerEntry
                key={player.id}
                player={player}
                isCurrentPlayer={currentPlayer?.id && player.id === currentPlayer.id}
                handleScrollCheck={handleScrollCheck}
                optionsStocks={productsStockByPlayer?.find((stock) => stock.playerId === player.id)?.stockByExtra}
              />
            ))}
          </div>

          {hosting?.length ? (
            <div className="m-b-10 p-r-40 p-l-40">
              <Hosting handleScrollCheck={handleScrollCheck} />
            </div>
          ) : null}

          <div
            className={`${styles.sticky_bottom} ${needScroll ? styles.need_scroll : ''}`}
          >
            <div className={`${styles.total} flex a-center j-spb p-20 m-b-20`}>
              <p className="fw-500 fs-20 p-r-20">{t('cart.total')}</p>
              <p className="fw-700 fs-20 ws-nowrap">
                {`${displayPrice(getTotalCart())} €`}
              </p>
            </div>

            <div className={styles.cta_btn}>
              <CartInformations />
              <NextButton isOutsideTunnel={isOutsideTunnel} />
            </div>
          </div>
        </>
      ) : <p className="p-r-40 p-l-40 p-t-20">{t('cart.empty')}</p>}
    </section>
  );
}

Cart.propTypes = {
  reverseBackground: PropTypes.bool,
  setCartIsOpened: PropTypes.func,
  isOutsideTunnel: PropTypes.bool,
};

Cart.defaultProps = {
  reverseBackground: false,
  setCartIsOpened: null,
  isOutsideTunnel: false,
};

export default Cart;
