import { format } from 'date-fns';
import { isNil } from 'lodash';
import languagesTypes from '../../enum/languages';
import sportsTypes from '../../enum/sports';
import parseFrenchDate from '../parseFrenchDate';

const getValueOrNull = (value) => (!isNil(value) && value !== '' ? value : null);

const getSports = (player) => {
  const sports = {};
  sportsTypes.forEach((sport) => {
    sports[sport.id] = {
      sportId: sport.id,
      isRanked: !isNil(getValueOrNull(player[sport.label]?.rankingCountry?.value))
        || !isNil(getValueOrNull(player[sport.label]?.otherRanking))
        || !isNil(getValueOrNull(player[sport.label]?.utrRanking))
        || false,
      years: getValueOrNull(player[sport.label]?.startYearPractice),
      hoursPerWeek: getValueOrNull(player[sport.label]?.playedHoursPerWeek),
      participationsPerYear: getValueOrNull(player[sport.label]?.gamesPerYear),
      rankingCountryCode: getValueOrNull(player[sport.label]?.rankingCountry?.countryCode),
      rankingCountryId: getValueOrNull(player[sport.label]?.rankingCountry?.value),
      otherRanking: getValueOrNull(player[sport.label]?.otherRanking),
      utrRanking: getValueOrNull(player[sport.label]?.utrRanking),
    };
  });

  return sports;
};

const getLanguages = (player) => {
  const languages = {};
  languagesTypes.forEach((language) => {
    languages[language.id] = {
      languageId: language.id,
      levelId: getValueOrNull(player[`${language.label}Level`]?.value),
    };
  });

  return languages;
};

const getFormattedPlayer = (player) => {
  const formatted = {
    firstname: player.firstname,
    lastname: player.lastname,
    sexId: player.gender?.value,
    nationalityId: player.nationality?.value,
    birthdate: player.birthdate ? format(parseFrenchDate(player.birthdate), 'yyyy-MM-dd') : null,
    email: player.email || null,
    phone: player.phone || null,

    playerSports: getSports(player),
    playerLanguages: getLanguages(player),

    emergencyContactLastname: player.emergencyContactLastname || null,
    emergencyContactFirstname: player.emergencyContactFirstname || null,
    emergencyContactOccupation: player.emergencyContactOccupation || null,
    emergencyContactPhone: player.emergencyContactPhone || null,

    doctorLastname: player.doctorLastname || null,
    doctorAddress: player.doctorAddress || null,
    doctorZipCode: player.doctorZipCode || null,
    doctorCity: player.doctorCity || null,
    doctorCountryId: player.doctorCountry?.value || null,

    socialSecurityAddress: player.socialSecurityAddress || null,
    socialSecurityZipCode: player.socialSecurityZipCode || null,
    socialSecurityCity: player.socialSecurityCity || null,
    socialSecurityCountryId: player.socialSecurityCountry?.value || null,

    medicalObservations: player.medicalObservations || null,
    diet: player.diet || null,
    medicalTermsAccepted: player.medicalTermsAccepted || false,
  };

  return formatted;
};

export default getFormattedPlayer;
