import extrasService from '../../services/extrasService';
import packagesService from '../../services/packagesService';

const hasAccommodationExtras = async (players) => {
  let extrasAvailable = false;

  if (players?.length) {
    // get current selected packages (players selection)
    const currentPackagesIds = players.map((p) => p.stage.packageId);

    // get data : all packages and all extras
    const packagesResult = await packagesService.getPackages();
    const allPackages = packagesResult?.data?.packages;
    const extrasResult = await extrasService.getExtras();
    const allExtras = extrasResult?.data?.extras;

    if (allExtras
      && Object.values(allExtras)?.filter((ext) => !ext.product?.archived)?.length
      && currentPackagesIds?.length
      && allPackages?.length
    ) {
      // filter packages by current players selection
      const currentPackages = allPackages.filter((p) => currentPackagesIds.includes(p.id));
      if (currentPackages?.length) {
        currentPackages.forEach((pack) => {
          // get given extras ids for each selected package
          const extrasIdsByPack = pack.extras.map((e) => e.id);

          if (extrasIdsByPack?.length) {
            // filter extras by current players selection
            const extrasByPack = Object.values(allExtras).filter(
              (ex) => extrasIdsByPack.includes(ex.id) && !ex.product?.archived,
            );

            // check if at least one extra is an accommodation extra
            const hasAccommodationsExtras = extrasByPack.some((pa) => pa.showIfWithAccommodations);
            if (hasAccommodationsExtras) extrasAvailable = true;
          }
        });
      }
    }
  }

  return extrasAvailable;
};

export default hasAccommodationExtras;
