import { isNil, isNumber } from 'lodash';

const billingComplete = (billing) => {
  if (billing && billing.address && billing.city && billing.zipCode && billing.country) return true;
  return false;
};

const setUserBookingProgress = (state, dispatch) => {
  if (state && state.players) {
    if (state.players.every((player) => player.completed)) {
      // all players are completed, cart is missing hosting
      if (isNil(state.hosting)) {
        dispatch({ type: 'SET_USER_PROGRESS', payload: 'hosting' }); // step - hosting
      } else if (
        state.players.some((player) => (
          !player.assignedTo
          || !player.tennisData
          || Object.values(player.tennisData)?.some((data) => isNil(data) || !isNumber(data)))
          || !player.sexId)
      ) {
        // players need assignments or player data
        dispatch({ type: 'SET_USER_PROGRESS', payload: 'assignment' }); // step - camp assignment
      } else if (!billingComplete(state.billingContact?.billingAddress) || !state.acceptLegalTerms) {
        // booking needs billing informations
        dispatch({ type: 'SET_USER_PROGRESS', payload: 'billing' }); // step - billing
      } else {
        // cart needs payment to be validated
        dispatch({ type: 'SET_USER_PROGRESS', payload: 'payment' }); // step - payment
      }
    } else if (isNil(state.currentPlayer?.stage)) {
      // current player has no camp
      dispatch({ type: 'SET_USER_PROGRESS', payload: 'stage' }); // step - stage
    } else if (isNil(state.currentPlayer?.options)) {
      // current player did not express if he wanted options
      dispatch({ type: 'SET_USER_PROGRESS', payload: 'options' }); // step - options
    } else {
      // current player is completed
      dispatch({ type: 'SET_USER_PROGRESS', payload: 'summary' }); // step - options
    }
  }
};

export default setUserBookingProgress;
