import React from 'react';
import PropTypes, { oneOf } from 'prop-types';

// Utils
import { isNil } from 'lodash';

// Icons
import MinusIcon from '../../../public/icons/minus.svg';
import PlusIcon from '../../../public/icons/plus.svg';

import styles from './InputNumber.module.scss';

function InputNumber({
  name, fieldName, value, onChange, setFieldTouched, setFieldValue, size, minValue, maxValue,
}) {
  const handleUp = () => {
    setFieldValue(fieldName, !isNil(maxValue) && (value + 1) > maxValue ? maxValue : value + 1);
    if (setFieldTouched) setFieldTouched(fieldName, true);
  };

  const handleDown = () => {
    setFieldValue(fieldName, !isNil(minValue) && (value - 1) < minValue ? minValue : value - 1);
    if (setFieldTouched) setFieldTouched(fieldName, true);
  };

  return (
    <div className={`${styles.input_number} flex a-center`}>
      <button
        type="button"
        onClick={handleDown}
        className={`${styles.update} ${styles.minus} flex center ${size ? styles[size] : ''}`}
        disabled={!isNil(minValue) && value <= minValue}
      >
        <MinusIcon />
      </button>
      <p className={`${styles.input_value} ${size ? styles[size] : ''}`}>
        {value}
      </p>
      <input
        id={name}
        name={name}
        disabled
        onChange={onChange}
        value={value}
        min={0}
      />
      <button
        type="button"
        onClick={handleUp}
        className={`${styles.update} flex center ${size ? styles[size] : ''}`}
        disabled={!isNil(maxValue) && value >= maxValue}
      >
        <PlusIcon />
      </button>
    </div>
  );
}

InputNumber.propTypes = {
  name: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  size: oneOf(['big', 'normal']),
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
};

InputNumber.defaultProps = {
  size: null,
  maxValue: null,
  minValue: 0,
};

export default InputNumber;
