export default function appReducer(state, action, setItem, initStore, clearAll, clearAllBooking) {
  switch (action.type) {
    case 'RESET_STATE': {
      clearAll();
      return initStore;
    }

    case 'RESET_BOOKING': {
      clearAllBooking();
      return initStore;
    }

    case 'SET_PREVIOUS_PATH': {
      setItem('previousPath', action.payload);
      return { ...state, previousPath: action.payload };
    }

    case 'SET_SEARCH': {
      setItem('search', action.payload);
      return { ...state, search: action.payload };
    }

    case 'SET_CURRENT_PLAYER': {
      setItem('currentPlayer', action.payload);
      return { ...state, currentPlayer: action.payload };
    }

    case 'SET_PLAYERS': {
      setItem('players', action.payload);
      return { ...state, players: action.payload };
    }

    case 'SET_USER_PROGRESS': {
      setItem('userProgress', action.payload);
      return { ...state, userProgress: action.payload };
    }

    case 'SET_HOSTING': {
      setItem('hosting', action.payload);
      return { ...state, hosting: action.payload };
    }

    case 'SET_EXTRAS_HOSTING': {
      setItem('extrasHosting', action.payload);
      return { ...state, extrasHosting: action.payload };
    }

    case 'SET_HOSTING_PARTICIPANTS': {
      setItem('hostingParticipants', action.payload);
      return { ...state, hostingParticipants: action.payload };
    }

    case 'SET_CART_UPDATES': {
      setItem('cartUpdates', action.payload);
      return { ...state, cartUpdates: action.payload };
    }

    case 'SET_BILLING_CONTACT': {
      setItem('billingContact', action.payload);
      return { ...state, billingContact: action.payload };
    }

    case 'SET_FREE_INSURANCE': {
      setItem('freeInsurance', action.payload);
      return { ...state, freeInsurance: action.payload };
    }

    case 'SET_COMPANIONS': {
      setItem('companions', action.payload);
      return { ...state, companions: action.payload };
    }

    case 'SET_DISCOUNT': {
      setItem('discountCode', action.payload);
      return { ...state, discountCode: action.payload };
    }

    case 'SET_USER': {
      setItem('user', action.payload);
      return { ...state, user: action.payload };
    }

    case 'SET_TRIGGER_MOBILE_SUMMARY': {
      setItem('triggerMobileSummary', action.payload);
      return { ...state, triggerMobileSummary: action.payload };
    }

    case 'SET_DATA_LAYER_DONE': {
      setItem('dataLayerDone', action.payload);
      return { ...state, dataLayerDone: action.payload };
    }

    case 'SET_ORDER_ID': {
      setItem('orderId', action.payload);
      return { ...state, orderId: action.payload };
    }

    case 'SET_SHOW_TAB_INDEX': {
      setItem('showTabIndex', action.payload);
      return { ...state, showTabIndex: action.payload };
    }

    case 'SET_CONTINUE_SKIP_PLAYER': {
      setItem('continueAndSkipPlayer', action.payload);
      return { ...state, continueAndSkipPlayer: action.payload };
    }

    case 'SET_CONFIRM_SKIP_OPENED': {
      setItem('confirmSkipPlayerIsOpened', action.payload);
      return { ...state, confirmSkipPlayerIsOpened: action.payload };
    }

    case 'SET_ACCEPT_LEGAL_TERMS': {
      setItem('acceptLegalTerms', action.payload);
      return { ...state, acceptLegalTerms: action.payload };
    }

    case 'SET_IS_HEADER_FOLDED': {
      setItem('isHeaderFolded', action.payload);
      return { ...state, isHeaderFolded: action.payload };
    }

    case 'SET_IS_BOOKING_NEXT_FOLDED': {
      setItem('isBookingNextFolded', action.payload);
      return { ...state, isBookingNextFolded: action.payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
