import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { useAppContext } from '../../../../src/store/AppContext';
import displayPrice from '../../../../src/utils/displayPrice';
import bookingStep from '../../../../src/enum/bookingStep';
import useDataLayer from '../../../../src/hooks/useDataLayer';
import getLanguageId from '../../../../src/utils/i18n/getLanguageId';
import useStocks from '../../../../src/hooks/useStocks';
import usePrice from '../../../../src/hooks/usePrice';

// Components
import PlayersCounter from '../../../organisms/Cart/PlayersCounter/PlayersCounter';
import PeriodsList from '../../../organisms/Cart/PlayerEntry/PeriodsList/PeriodsList';
import Button from '../../../atoms/Button/Button';
import SummaryPrice from '../SummaryPrice/SummaryPrice';
import NextButton from '../../../organisms/Cart/NextButton/NextButton';
import ToggleCheck from '../../../molecules/ToggleCheck/ToggleCheck';

import styles from './CartSummary.module.scss';

function CartSummary({
  billingNextDisabled, lastStep, isOutsideTunnel, credits,
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const { locale } = router;
  const isLaptop = useMediaQuery({ minWidth: 1200 });

  const [{
    players, hosting, extrasHosting, userProgress, acceptLegalTerms, billingContact, freeInsurance,
  }, stateDispatch] = useAppContext();

  const { gtmEventCartSummary } = useDataLayer();
  const { getStockExtraByPlayer } = useStocks();
  const { getTotalCart, getTotalPlayerPrice } = usePrice();

  const [needScroll, setNeedScroll] = useState(false);
  const cartContent = useRef('cartContent');

  const [productsStockByPlayer, setProductsStockByPlayer] = useState(null);

  const handleScrollCheck = () => {
    if (cartContent?.current) setNeedScroll(cartContent.current.scrollHeight > cartContent.current.clientHeight);
  };

  useEffect(() => {
    handleScrollCheck();
    window.addEventListener('resize', handleScrollCheck, true);

    if (!isOutsideTunnel && !lastStep) {
      gtmEventCartSummary();
    }

    return () => window.removeEventListener('resize', handleScrollCheck, true);
  }, []);

  useEffect(() => {
    if (!productsStockByPlayer && players?.length) {
      getStockExtraByPlayer(setProductsStockByPlayer, players);
    }
  }, [players]);

  const getQuantity = (option) => {
    let quantity = 0;
    if (option.quantities?.length) option.quantities.forEach((q) => { quantity += q.quantity; });
    return quantity;
  };

  const getHostingPrice = (host) => {
    let price = 0;
    if (host?.price && host?.quantity) price += (host.quantity * host.price);
    return price;
  };

  const getParticipants = (host) => {
    const label = [];
    let babies = 0;
    let children = 0;
    let adults = 0;

    if (host?.participants) {
      adults = host.participants.adult?.length || 0;

      if (host.participants.child?.length) {
        host.participants.child.forEach((child) => {
          if (child.typeId === 3) babies += 1;
          if (child.typeId === 1) children += 1;
        });
      }
    }

    if (adults > 0) label.push(`${adults} ${t(`payment.hosting.${adults > 1 ? 'adults' : 'adult'}`)}`);
    if (children > 0) label.push(`${children} ${t(`payment.hosting.${children > 1 ? 'children' : 'child'}`)}`);
    if (babies > 0) label.push(`${babies} ${t(`payment.hosting.${babies > 1 ? 'babies' : 'baby'}`)}`);

    return label.join(', ');
  };

  const getExtraHostingsByType = (type) => extrasHosting.filter((ex) => ex.formType === type);

  const getExtraHostingComponent = (type) => (
    getExtraHostingsByType(type)?.length ? (
      <p className="text-c-primary fw-500 flex a-center j-spb m-b-5">
        <span className="fs-14 p-r-20">
          {`x ${getExtraHostingsByType(type)[0].quantity} 
          ${t(`hosting.extras.modal.${type}`)}`}
        </span>

        <span className="ws-nowrap text-c-black-700 fw-700">
          {`${displayPrice(
            getExtraHostingsByType(type)[0].price * getExtraHostingsByType(type)[0].quantity,
          )} €`}
        </span>
      </p>
    ) : null
  );

  const handleGoToBooking = () => {
    if (userProgress && bookingStep.route[userProgress]) {
      router.push(bookingStep.route[userProgress]);
    }
  };

  return (
    <section ref={cartContent} className={`${styles.cart_wrapper}`}>
      <div className={`${styles.sticky_top} flex a-center j-spb m-b-10`}>
        <p className="fs-20 text-c-primary fw-700 mobile-fs-24">{t('payment.summary')}</p>
        {isLaptop ? <PlayersCounter className={`${styles.counter} flex a-center`} /> : null}
      </div>

      {players?.length ? (
        <>
          <div className="m-b-10 p-r-40 p-l-40 tablet-p-r-20 tablet-p-l-20">
            {players.filter((p) => p.stage).map((player) => (
              <div key={`player_${player.id}`} className={`${styles.player_entry} flex j-spb w-100`}>
                <div className="p-r-20">
                  <p className="fw-500 text-c-primary m-b-5">{player.stage.packageName[getLanguageId(locale)]}</p>
                  <PeriodsList
                    offersIds={player.stage.weeks}
                    durationId={player.stage.durationId}
                    days={player.stage.durationInDays}
                  />
                  <div className="m-t-5 text-c-black-600 fs-14">
                    {player.options?.map((option) => (
                      <p key={`player${player.id}_option${option.id}`}>
                        {`x ${getQuantity(option)} ${option.label[getLanguageId(locale)]}`}
                      </p>
                    ))}
                    {player.otherOptions?.map((option) => (
                      <p key={`player${player.id}_option${option.id}`}>
                        {`x ${getQuantity(option)} ${option.label[getLanguageId(locale)]}`}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="fw-700 fw-700 ws-nowrap">
                  {`${displayPrice(getTotalPlayerPrice(player))} €`}
                </div>
              </div>
            ))}
          </div>

          {hosting?.length ? (
            <div
              className={
                `${extrasHosting?.length || billingContact?.hasInsurance
                  ? 'm-b-20'
                  : 'm-b-40'} p-r-40 p-l-40 tablet-p-r-20 tablet-p-l-20 tablet-m-b-0`
              }
            >
              {hosting.map((host) => (
                <div key={`hosting_${host.id}`} className={`${styles.player_entry} flex j-spb w-100`}>
                  <div className="p-r-20">
                    <p className="fw-500 text-c-primary m-b-5">
                      {host.quantity > 1
                        ? `${host.quantity} x ${host.label[getLanguageId(locale)]}`
                        : host.label[getLanguageId(locale)]}
                    </p>
                    <p className="text-c-primary fw-400 m-b-5">{getParticipants(host)}</p>
                    <PeriodsList
                      offersIds={players[0].stage.weeks}
                      durationId={players[0].stage.durationId}
                      days={players[0].stage.durationInDays}
                    />
                  </div>
                  <div className="fw-700 ws-nowrap">
                    {`${displayPrice(getHostingPrice(host))} €`}
                  </div>
                </div>
              ))}
            </div>
          ) : null}

          {/* Pick-up & Drop-off */}
          {extrasHosting?.length ? (
            <div className="m-b-30 p-r-40 p-l-40 tablet-p-r-20 tablet-p-l-20 tablet-m-b-0">
              {getExtraHostingComponent('pickup')}
              {getExtraHostingComponent('dropoff')}

              <div className="grey-separator" />
            </div>
          ) : null}

          {billingContact?.hasInsurance ? (
            <div className="m-b-40 p-r-40 p-l-40 tablet-p-r-20 tablet-p-l-20 tablet-m-b-0">
              <div className="flex a-center j-spb ">
                <p className="text-c-primary fw-500 flex a-center j-spb p-r-20">
                  {t('payment.billing.sections.insurance')}
                </p>
                <p className="text-c-black-600 fw-500">
                  {freeInsurance ? t('payment.billing.form.insurance.free') : (
                    `${
                      displayPrice(
                        // total * 1.07 - total
                        (getTotalCart() * 1.07) - getTotalCart(),
                      )
                    } €`
                  )}
                </p>
              </div>
            </div>
          ) : null}

          {lastStep ? (
            <div className="m-b-40 p-r-40 p-l-40 tablet-p-r-20 tablet-p-l-20 tablet-m-b-0">
              {/* <DiscountEntry /> */}

              {credits && credits > 0 ? (
                <div className="flex a-center j-spb m-t-10">
                  <p className="text-c-primary fw-500 flex a-center j-spb m-b-5 p-r-20">
                    {t('payment.credit.label')}
                  </p>
                  <p className="text-c-black-600 fw-500">
                    {`- ${displayPrice(credits)} €`}
                  </p>
                </div>
              ) : null}
            </div>
          ) : null}

          <div
            className={`${styles.sticky_bottom} ${needScroll ? styles.need_scroll : ''}`}
          >
            {/* {!lastStep ? (
              <div className={`${styles.discount} m-b-20 tablet-m-b-0`}>
                {!isLaptop ? (
                  <p className="text-c-primary fw-500 m-b-10">{t('payment.promotionCode')}</p>
                ) : null}
                <DiscountCode />
              </div>
            ) : null} */}

            {isLaptop ? (
              <>
                <div className={`${styles.total} flex a-center j-spb m-b-20`}>
                  <p className="fw-500 fs-20 p-r-20">
                    {t('payment.total')}
                    <span className="fs-14 fw-400 m-l-10">
                      {`(${t('payment.total.taxes')})`}
                    </span>
                  </p>
                  <SummaryPrice credits={credits} />
                </div>
                {!lastStep ? (
                  <div className={styles.cta_btn}>
                    <ToggleCheck
                      labels={[
                        <span
                          key={1}
                          className={`${styles.legals} fs-12 text-c-black-600 fw-400`}
                          dangerouslySetInnerHTML={{ __html: `* ${t('payment.form.legals')}` }}
                        />,
                      ]}
                      name="acceptLegalTerms"
                      onValueChange={(checked) => stateDispatch({ type: 'SET_ACCEPT_LEGAL_TERMS', payload: checked })}
                      isChecked={acceptLegalTerms}
                      className="m-t-30 m-b-20"
                    />

                    <NextButton billingNextDisabled={billingNextDisabled} />
                  </div>
                ) : null}

                {isOutsideTunnel ? (
                  <Button
                    full
                    text="uppercase"
                    onClick={handleGoToBooking}
                  >
                    {t('cart.goToBooking')}
                  </Button>
                ) : null}
              </>
            ) : null}
          </div>
        </>
      ) : <p className="p-r-40 p-l-40 p-t-20">{t('cart.empty')}</p>}
    </section>
  );
}

CartSummary.propTypes = {
  billingNextDisabled: PropTypes.bool,
  lastStep: PropTypes.bool,
  isOutsideTunnel: PropTypes.bool,
  credits: PropTypes.number,
};

CartSummary.defaultProps = {
  billingNextDisabled: false,
  lastStep: false,
  isOutsideTunnel: false,
  credits: null,
};

export default CartSummary;
