import React from 'react';

// Utils
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import bookingStep from '../../../../src/enum/bookingStep';
import { useAppContext } from '../../../../src/store/AppContext';
import useCart from '../../../../src/hooks/useCart';

// Components
import FlashMessage from '../../../molecules/FlashMessage/FlashMessage';

function CartInformations() {
  const { t } = useTranslation();
  const router = useRouter();
  const [{ players, hosting, hostingParticipants }] = useAppContext();

  const { getPlayerLabel } = useCart();

  const getFlashComponent = (message, color) => (
    <div className="m-b-10">
      <FlashMessage color={color} message={message} />
    </div>
  );

  const getHostedParticipants = () => {
    const participantsPlayers = [];
    if (hosting?.length) {
      hosting.forEach((host) => {
        host.participants?.adult?.forEach((adult) => {
          participantsPlayers.push(adult.id);
        });
        host.participants?.child?.forEach((child) => {
          participantsPlayers.push(child.id);
        });
      });
    }

    return participantsPlayers;
  };

  const hostingCompleted = () => {
    if (hostingParticipants?.length && getHostedParticipants()?.length) {
      return hostingParticipants.every((p) => getHostedParticipants().some((hp) => hp === p.id));
    }
    return false;
  };

  const getMissingHostingFlash = (playersOrAcc, type, typeMultiple) => (playersOrAcc.length ? (
    playersOrAcc.length === 1 ? (
      getFlashComponent(t(`hosting.cart.informations.missing.${type}`, {
        name: playersOrAcc[0].playerId
          ? getPlayerLabel({
            typeLabel: playersOrAcc[0].typeLabel,
            typeId: playersOrAcc[0].typeId,
            playerId: playersOrAcc[0].playerId,
          })
          : playersOrAcc[0].label,
      }), 'primary')
    ) : (
      getFlashComponent(t(`hosting.cart.informations.missing.${typeMultiple}`, {
        name: playersOrAcc
          .map((player) => (player.playerId
            ? getPlayerLabel({ typeLabel: player.typeLabel, typeId: player.typeId, playerId: player.playerId })
            : player.label))
          .join(', '),
      }), 'primary')
    )
  ) : null);

  const getMissingHosting = () => {
    const playerMissing = [];
    const companionMissing = [];

    hostingParticipants?.forEach((participant) => {
      if (!getHostedParticipants().includes(participant.id)) {
        if (participant.playerId) {
          playerMissing.push(participant);
        } else {
          companionMissing.push(participant);
        }
      }
    });

    return (
      <div>
        {getMissingHostingFlash(playerMissing, 'player', 'players')}
        {getMissingHostingFlash(companionMissing, 'companion', 'companions')}
      </div>
    );
  };

  return (
    <div>
      {router.pathname === bookingStep.route.summary ? (
        getFlashComponent(
          players?.every((pl) => pl.completed) ? t('summary.players.allCompleted') : t('summary.hostingLater'),
          players?.every((pl) => pl.completed) ? 'success' : 'primary',
        )
      ) : null}

      {router.pathname === bookingStep.route.hosting && hosting ? (
        <>
          {hosting.length === 0 ? getFlashComponent(t('hosting.cart.informations.none'), 'primary') : null}

          {hosting.length ? (
            hostingCompleted() ? (
              getFlashComponent(t('hosting.cart.informations.complete'), 'success')
            ) : (
              getMissingHosting()
            )
          ) : null}
        </>
      ) : null}

      {router.pathname === bookingStep.route.assignment ? (
        getFlashComponent(t(`assignment.cart.informations.tennis.${players?.length === 1 ? 'single' : 'multi'}`))
      ) : null}
    </div>
  );
}

CartInformations.propTypes = {};

export default CartInformations;
