import { addDays } from 'date-fns';
import { range, uniq } from 'lodash';
import useWeekId from './useWeekId';

const useWeeksSlots = () => {
  const { getDateFromWeekId, getWeekIdFromDate, getOfferDatesFromWeekId } = useWeekId();

  const getWeeksBySlotByPlayer = (player) => {
    const weeksBySlot = [];
    const camp = player?.stage;

    if (camp) {
      const isWeekend = camp.durationId === 2;
      const numberOfDays = camp.days;

      const firstWeeks = camp.weeks;

      if (firstWeeks?.length) {
        firstWeeks.forEach((weekId) => {
          let middleWeeks = [];
          const lastWeeks = [];

          const start = isWeekend
            ? addDays(getDateFromWeekId(weekId), 5)
            : getDateFromWeekId(weekId);
          const end = addDays(start, numberOfDays - 1);

          if (start && end) {
            const startWeekId = getWeekIdFromDate(start);
            const endWeekId = getWeekIdFromDate(end);
            if (endWeekId) lastWeeks.push(endWeekId);

            let weeksRange = range(startWeekId, endWeekId);
            if (weeksRange?.length) weeksRange = weeksRange.filter((w) => w !== startWeekId && w !== endWeekId);
            if (weeksRange?.length) {
              middleWeeks = weeksRange;
            }
          }

          let all = [weekId].concat(middleWeeks).concat(lastWeeks);
          if (all?.length) all = uniq(all.sort((a, b) => (a > b ? 1 : -1)));

          weeksBySlot.push({
            label: getOfferDatesFromWeekId({
              weekId,
              isWeekend: camp.durationId === 2,
              days: camp.durationInDays,
              withShortMonth: true,
            }),
            weeks: all,
          });
        });
      }
    }

    return weeksBySlot;
  };

  return {
    getWeeksBySlotByPlayer,
  };
};

export default useWeeksSlots;
