import { useAppContext } from '../store/AppContext';

const usePrice = () => {
  const [{
    players, hosting, extrasHosting, billingContact, discountCode, freeInsurance,
  }] = useAppContext();

  const getTotalCampPrice = (stage) => {
    let total = 0;
    stage.offers.forEach((offer) => { total += offer.price / 100; });
    return total;
  };

  const getOptionsTotal = (options) => {
    let total = 0;
    options.forEach((option) => {
      let totalOption = 0;
      option.quantities?.forEach((q) => { totalOption += (q.price * q.quantity); });
      total += totalOption;
    });
    return total;
  };

  const getTotalPlayerPrice = (player) => {
    // camp
    let total = player.stage ? getTotalCampPrice(player.stage) : 0;

    // options
    if (player.options) total += getOptionsTotal(player.options);
    if (player.otherOptions) total += getOptionsTotal(player.otherOptions);

    return total;
  };

  const getTotalCart = () => {
    let total = 0;
    // for each player, add up the selected offers + selected options
    // add up hosting price and hosting extras

    if (players?.length) {
      players.forEach((player) => { total += getTotalPlayerPrice(player); });

      if (hosting?.length) {
        hosting.forEach((host) => { total += host.quantity * host.price; });
      }
    }

    if (extrasHosting?.length) {
      extrasHosting.forEach((extra) => { total += (extra.quantity * extra.price); });
    }

    return total;
  };

  const getTotalOrder = () => {
    let amount = getTotalCart();

    // optionnal insurance
    if (billingContact?.hasInsurance && !freeInsurance) amount *= 1.07;

    return amount;
  };

  const getDiscount = (amount) => {
    let discount = 0;

    if (discountCode) {
      // absolute discount
      if (discountCode.absoluteValue && !discountCode.relativeValue) {
        discount = discountCode.absoluteValue;
      }

      // relative discount
      if (discountCode.relativeValue && !discountCode.absoluteValue) {
        const absolute = amount * discountCode.relativeValue;
        discount = absolute;
      }
    }

    return discount;
  };

  const getDiscountedAmount = (amount) => {
    const discount = getDiscount(amount);
    const discounted = amount - discount;
    return discounted >= 0 ? discounted : 0;
  };

  const getTotalAfterCredits = (amount, credits) => amount - credits;

  const getUsableCredit = (credits) => Math.min(credits || 0, getDiscountedAmount(getTotalOrder()));

  return {
    getTotalOrder,
    getDiscount,
    getDiscountedAmount,
    getTotalAfterCredits,
    getUsableCredit,
    getTotalCart,
    getTotalPlayerPrice,
    getTotalCampPrice,
  };
};

export default usePrice;
