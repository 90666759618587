import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import { useRouter } from 'next/router';
import { isNil } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useAppContext } from '../../../../src/store/AppContext';
import triggerCollapse from '../../../../src/utils/collapsibleElement';
import bookingStep from '../../../../src/enum/bookingStep';
import displayPrice from '../../../../src/utils/displayPrice';
import getLanguageId from '../../../../src/utils/i18n/getLanguageId';

// Icons
import UserIcon from '../../../../public/icons/user.svg';

// Components
import TrashItem from '../../../molecules/TrashItem/TrashItem';
import PeriodsList from '../PlayerEntry/PeriodsList/PeriodsList';

import styles from './Hosting.module.scss';

function Hosting({ handleScrollCheck }) {
  const { t } = useTranslation();
  const router = useRouter();
  const { locale } = router;

  const [{
    hosting, extrasHosting, players,
  }] = useAppContext();

  const [currentTrigger, setCurrentTrigger] = useState(null);
  const [currentCollapsed, setCurrentCollapsed] = useState(null);

  const handleCollapse = (open, ajustTransition) => {
    if (currentTrigger && currentCollapsed) {
      triggerCollapse(currentCollapsed, currentTrigger, open, ajustTransition);
      setTimeout(() => { handleScrollCheck(); }, 400);
    }
  };

  useEffect(() => {
    if (hosting?.length) triggerCollapse(currentCollapsed, currentTrigger, true, false);
  }, [hosting]);

  useEffect(() => {
    const collapsedElement = document.querySelector('#hostingCollapsed');
    const triggerElement = document.querySelector('#hostingTriggerCollapse');
    setCurrentCollapsed(collapsedElement);
    setCurrentTrigger(triggerElement);

    if (bookingStep.stepFromPath[router.pathname] === 'hosting') {
      triggerCollapse(collapsedElement, triggerElement, true, false);
      setTimeout(() => { handleScrollCheck(); }, 400);
    }
  }, []);

  const getTotalPrice = () => {
    let total = 0;
    if (hosting?.length && players?.length) {
      hosting.forEach((h) => {
        const quantity = !isNil(h.quantity) ? h.quantity : 1;
        total += quantity * h.price;
      });
    }
    return total;
  };

  const getPeopleCount = (participants) => participants.adult.length + participants.child.length;

  const getComponent = (item, i, type) => (
    <div key={`${type}-${i}`} className={styles.hosting_item}>
      <div className="flex j-spb">
        <p className="text-c-primary fw-500 p-r-20 lh-1-2 p-b-5 p-t-2">
          {`${!isNil(item.quantity) ? item.quantity : 1} x ${item.label[getLanguageId(locale)]}`}
        </p>
        <p className="fw-500 m-l-auto ws-nowrap">
          {`${displayPrice(item.quantity * item.price)} €`}
        </p>

        {!item?.isMandatory ? (
          <TrashItem
            hostingId={type === 'hosting' ? item.id : item.formType}
            type={type}
            className="text-c-secondary p-l-15 p-b-5 c-pointer"
          />
        ) : null}
      </div>
      <p className="text-c-primary fw-400 m-b-5">
        {`
          ${getPeopleCount(item.participants)} 
          ${t(getPeopleCount(item.participants) > 1 ? 'hosting.people.plural' : 'hosting.people')}
        `}
      </p>
      {type === 'hosting' && players?.length && players[0]?.stage ? (
        <PeriodsList
          offersIds={players[0]?.stage.weeks}
          durationId={players[0]?.stage.durationId}
          days={players[0]?.stage.durationInDays}
        />
      ) : null}
    </div>
  );

  return (
    <div className={styles.hosting}>
      <button
        id="hostingTriggerCollapse"
        type="button"
        className={`
          ${styles.details_opener} 
          flex a-center j-spb w-100 collapsible collapsible-players c-pointer
        `}
        onClick={() => handleCollapse(!currentTrigger.classList.contains('active'), false)}
      >
        <p className="flex a-center m-r-auto fw-500 text-c-secondary">
          <UserIcon className="m-r-5" />
          {t('cart.hosting')}
        </p>
        <span className="fw-700 fs-16">{`${displayPrice(getTotalPrice())} €`}</span>
      </button>
      <div id="hostingCollapsed">
        {hosting.map((host, i) => (
          getComponent(host, i, 'hosting')
        ))}
        {extrasHosting ? extrasHosting.map((extra, i) => (
          getComponent(extra, i, 'extraHosting')
        )) : null}
      </div>
    </div>
  );
}

Hosting.propTypes = {
  handleScrollCheck: PropTypes.func,
};

Hosting.defaultProps = {
  handleScrollCheck: () => {},
};

export default Hosting;
