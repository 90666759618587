import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import { useTranslation } from 'next-i18next';
import { useFormik } from 'formik';
import { useMediaQuery } from 'react-responsive';
import { cloneDeep, sortBy } from 'lodash';
import { useAppContext } from '../../../src/store/AppContext';
import updateOptionForPlayer from '../../../src/utils/booking/updateOptionForPlayer';
import displayPrice from '../../../src/utils/displayPrice';
import useWeekId from '../../../src/hooks/useWeekId';

// Components
import InputNumber from '../../molecules/InputNumber/InputNumber';

function PlayerOptionCounter({
  player, isOther, option, optionStocks, inSummary, inAssignment,
}) {
  const { t } = useTranslation(['common']);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [{ players }, stateDispatch] = useAppContext();
  const { getOfferDatesFromWeekId } = useWeekId();

  const [initialValues, setInitialValues] = useState({});

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
  });

  useEffect(() => {
    updateOptionForPlayer(formik, player, players, stateDispatch, isOther ? 'otherOptions' : 'options', option.id);
  }, [formik.values]);

  const getQuantities = (quantities) => {
    const quantitiesField = {};
    quantities.forEach((q) => {
      quantitiesField[q.weekId] = q.quantity;
    });
    return quantitiesField;
  };

  const populateWithOption = (opt) => {
    if (opt) {
      let formikValues = {};
      opt.forEach((o) => {
        if (o.quantities?.length) {
          formikValues = {
            ...formikValues,
            [o.id]: getQuantities(o.quantities),
          };
        }
      });
      setInitialValues(formikValues);
    }
  };

  useEffect(() => {
    if (player) {
      populateWithOption(isOther ? player.otherOptions : player.options);
    }
  }, [player]);

  const getOptionWeekLabel = (week) => {
    const camp = player?.stage;

    if (camp) {
      if (camp.weeks?.length) {
        // if only one week in slot, juste display slot without week number
        if (camp.days < 8) {
          return getOfferDatesFromWeekId({
            weekId: week.weekId,
            isWeekend: camp.durationId === 2,
            days: camp.durationInDays,
            withShortMonth: true,
          });
        }

        // if first week of slot => week number 1
        if (camp.weeks.includes(week.weekId)) {
          const date = getOfferDatesFromWeekId({
            weekId: week.weekId,
            isWeekend: camp.durationId === 2,
            days: camp.durationInDays,
            withShortMonth: true,
          });
          return `${date} - ${t('booking.choice.option.week')} 1`;
        }

        // else, find week number
        const weeksCloned = cloneDeep(camp.weeks);
        weeksCloned.push(week.weekId);
        weeksCloned.sort();
        const currentWeekIndex = weeksCloned.findIndex((a) => a === week.weekId);
        const refWeek = weeksCloned[currentWeekIndex - 1];

        if (refWeek) {
          const date = getOfferDatesFromWeekId({
            weekId: refWeek,
            isWeekend: camp.durationId === 2,
            days: camp.durationInDays,
            withShortMonth: true,
          });
          return `${date} - ${t('booking.choice.option.week')} ${week.weekId - refWeek + 1}`;
        }
      }
    }
    return '';
  };

  const getStockByWeekId = (weekId) => optionStocks?.find((opt) => opt.weekId === weekId);

  return (
    option.quantities?.length ? (
      sortBy(option.quantities, 'weekId').map((week, i) => (
        <div key={i} className="flex a-center j-spb p-b-10">
          <p className={`
            ${inSummary ? 'fs-16 fw-500 mobile-fs-14' : 'fs-14 fw-400 text-c-primary p-r-10'} 
            ${inAssignment ? 'fs-14 fw-500 text-c-black-700' : ''}`}
          >
            {getOptionWeekLabel(week)}
          </p>

          {inSummary && !isMobile && getStockByWeekId(week.weekId) ? (
            <p className="m-l-auto m-r-30 fs-18 fw-700">
              {`${displayPrice(getStockByWeekId(week.weekId)?.price)} €`}
            </p>
          ) : null}

          <InputNumber
            name={`player_${player.id}_${option.id}_${i}`}
            fieldName={`${option.id}.${week.weekId}`}
            value={week.quantity}
            onChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
            maxValue={Math.min(
              getStockByWeekId(week.weekId)?.stock || 0,
              (option.optionalQuantity + option.fixedQuantity),
            )} // maximum = min(stock, optional + fixed)
            minValue={option.fixedQuantity || 0}
            size={inSummary && !isMobile ? 'big' : null}
          />
        </div>
      ))
    ) : null
  );
}

PlayerOptionCounter.propTypes = {
  player: PropTypes.shape({}).isRequired,
  isOther: PropTypes.bool.isRequired,
  option: PropTypes.shape({}).isRequired,
  optionStocks: PropTypes.arrayOf(PropTypes.shape({})),
  inSummary: PropTypes.bool,
  inAssignment: PropTypes.bool,
};

PlayerOptionCounter.defaultProps = {
  optionStocks: null,
  inSummary: false,
  inAssignment: false,
};

export default PlayerOptionCounter;
