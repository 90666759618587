import { cloneDeep } from 'lodash';
import triggerConfirmDelete from './triggerConfirmDelete';

const updateOptionForPlayer = (formik, player, players, stateDispatch, optionType, optionId) => {
  const updatedOptions = cloneDeep(player[optionType]);
  const currentOption = updatedOptions?.findIndex((op) => op.id === +optionId);
  const optionsValues = formik.values[optionId];

  if (optionsValues && Object.keys(optionsValues)?.length && currentOption > -1) {
    Object.keys(optionsValues).forEach((weekId) => {
      const quantityByWeek = formik.values[optionId][weekId];
      const currentOptionQuantities = updatedOptions[currentOption].quantities;

      if (currentOptionQuantities?.length) {
        const existingWeekQuantity = currentOptionQuantities.find((q) => q.weekId === +weekId);
        if (existingWeekQuantity && existingWeekQuantity?.quantity !== quantityByWeek) {
          if (quantityByWeek === 0) {
            // if quantity === 0, ask user if (s)he wants to delete option
            triggerConfirmDelete.deleteItemByPlayer('option', optionId, player.id, weekId);
            formik.setFieldValue(`${optionId}.${[weekId]}`, 1);
          } else {
            // else adjust quantity
            existingWeekQuantity.quantity = quantityByWeek;
          }
        }
      }
    });

    const updatedPlayers = cloneDeep(players);
    const playerIndex = updatedPlayers.findIndex((p) => p.id === player.id);
    if (playerIndex > -1) {
      updatedPlayers[playerIndex][optionType] = updatedOptions;
      stateDispatch({
        type: 'SET_PLAYERS',
        payload: updatedPlayers,
      });
    }
  }
};

export default updateOptionForPlayer;
