import useApi from './useApi';

const useCartService = () => {
  const {
    requestState, payload, error, api,
  } = useApi();

  const putCartMine = (cart, localeId) => api({
    method: 'put',
    url: '/carts/mine',
    data: {
      localeId: localeId || 2,
      ...cart,
    },
  });

  const postInitStripePayment = (orderId) => api({
    method: 'post',
    url: orderId
      ? `/orders/${orderId}:initialize-stripe-payment`
      : '/carts/mine:initialize-stripe-payment',
  });

  const postInitPaypalPayment = (orderId, paymentId) => {
    const orderRoute = paymentId
      ? `/orders/${paymentId}:continue-paypal-payment`
      : `/orders/${orderId}:initialize-paypal-payment`;

    return api({
      method: 'post',
      url: (paymentId || orderId) ? orderRoute : '/carts/mine:initialize-paypal-payment',
    });
  };

  const postFinalizePaypalPayment = (orderId) => api({
    method: 'post',
    url: '/paypal:finalize-payment',
    data: {
      payPalOrderId: orderId,
    },
  });

  const postPayWithCredits = () => api({
    method: 'post',
    url: '/carts/mine:pay-with-credits',
  });

  const postContinueStripePayment = (orderId) => api({
    method: 'post',
    url: `/orders/${orderId}:continue-stripe-payment`,
  });

  return [
    requestState,
    payload,
    error,
    {
      putCartMine,
      postInitStripePayment,
      postInitPaypalPayment,
      postFinalizePaypalPayment,
      postPayWithCredits,
      postContinueStripePayment,
    }];
};

export default useCartService;
