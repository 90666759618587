// Utils
import extrasService from '../services/extrasService';

// Services
import packagesService from '../services/packagesService';
import useWeeksSlots from './useWeeksSlots';

const useStocks = () => {
  const { getWeeksBySlotByPlayer } = useWeeksSlots();

  const getStocksByExtra = async (packageId, allExtras, player) => {
    // get current package by id
    const currentPack = await packagesService.getPackage(packageId);
    const currentPackData = currentPack ? currentPack.data : [];

    // get extras ids in package
    let extrasIdsInPackage = [];
    if (currentPackData?.extras) extrasIdsInPackage = currentPackData.extras.map((ext) => ext.id);

    // get full extras by ids
    let extrasInPackage = [];
    if (allExtras && Object.values(allExtras)?.length) {
      extrasInPackage = Object.values(allExtras).filter(
        (extra) => extrasIdsInPackage.includes(extra.id) && !extra.product?.archived,
      );
    }

    // get all weeks for camp
    const allWeeks = [];
    const allWeeksBySlots = getWeeksBySlotByPlayer(player);
    if (allWeeksBySlots?.length) {
      allWeeksBySlots.forEach((slot) => {
        slot.weeks.forEach((week) => {
          allWeeks.push(week);
        });
      });
    }

    // get stocks for specified weeks
    const stocksByWeeks = await extrasService.getStocks(allWeeks, 2);

    const optionsStocks = [];

    // for each extra in package, get matching stock by week
    if (stocksByWeeks?.data?.productsStocks?.length && extrasInPackage?.length) {
      extrasInPackage.forEach((extra, extraIndex) => {
        optionsStocks.push({ optionId: extra.id, optionStocks: [] });
        stocksByWeeks.data.productsStocks
          .filter((ps) => ps.productId === extra.product?.id)
          .forEach((ps) => {
            optionsStocks[extraIndex].optionStocks.push(ps);
          });
      });
    }

    return optionsStocks;
  };

  const getStockExtraByPlayer = async (setProductsStockByPlayer, players) => {
    // get all extras
    extrasService.getExtras().then((extrasRes) => {
      if (extrasRes?.data?.extras) {
        const result = [];

        // for each player
        const completePromise = Promise.all(players.map(async (player) => {
          if (player.stage) {
            // get stock by extra
            const res = await getStocksByExtra(player.stage.packageId, extrasRes.data.extras, player);
            result.push({ playerId: player.id, stockByExtra: res });
          } else {
            result.push({ playerId: player.id, stockByExtra: [] });
          }
        }));

        completePromise.then(() => {
          setProductsStockByPlayer(result);
        });
      }
    });
  };

  return {
    getStocksByExtra,
    getStockExtraByPlayer,
  };
};

export default useStocks;
