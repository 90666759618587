import { isNil } from 'lodash';

const useStorage = () => {
  const isBrowser = (() => typeof window !== 'undefined')();

  const getItem = (key) => (
    isBrowser
    && !isNil(window?.localStorage)
    && window.localStorage[key] ? JSON.parse(window.localStorage[key]) : null);

  const setItem = (key, value) => {
    if (isBrowser) {
      window.localStorage.setItem(key, JSON.stringify(value));
      return true;
    }

    return false;
  };

  const removeItem = (key) => {
    if (isBrowser && window.localStorage[key]) {
      window.localStorage.removeItem(key);
    }
  };

  const clearAllBooking = () => {
    // clear all but keep user/token
    if (isBrowser && window.localStorage && Object.keys(window.localStorage)?.length) {
      const allKeysButAuth = Object.keys(window.localStorage)
        .filter((key) => key !== 'user' && key !== 'token' && key !== 'appVersion');
      allKeysButAuth.forEach((key) => removeItem(key));
    }
  };

  const clearAll = () => {
    // clear all
    if (isBrowser && window.localStorage && Object.keys(window.localStorage)?.length) {
      const allKeysButVersion = Object.keys(window.localStorage)
        .filter((key) => key !== 'appVersion');
      allKeysButVersion.forEach((key) => removeItem(key));
    }
  };

  // SESSION STORAGE

  const getSessionItem = (key) => (isBrowser && window.sessionStorage[key]
    ? JSON.parse(window.sessionStorage[key])
    : '');

  const setSessionItem = (key, value) => {
    if (isBrowser) {
      window.sessionStorage.setItem(key, JSON.stringify(value));
      return true;
    }

    return false;
  };

  const removeSessionItem = (key) => {
    if (isBrowser && window.sessionStorage[key]) {
      window.sessionStorage.removeItem(key);
    }
  };

  return {
    getItem,
    setItem,
    removeItem,
    clearAllBooking,
    clearAll,
    getSessionItem,
    setSessionItem,
    removeSessionItem,
  };
};

export default useStorage;
