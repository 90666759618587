// Utils
import { useRouter } from 'next/router';
import { uniq, uniqBy } from 'lodash';
import { format, isDate } from 'date-fns';
import { useAppContext } from '../store/AppContext';
import packagesTypes from '../enum/packagesTypes';
import useCart from './useCart';
import getLanguageId from '../utils/i18n/getLanguageId';
import bookingStep from '../enum/bookingStep';
import usePrice from './usePrice';
import useWeekId from './useWeekId';

const useDataLayer = () => {
  const router = useRouter();

  const [{
    discountCode, dataLayerDone, orderId, currentPlayer, players, hosting,
  }, stateDispatch] = useAppContext();

  const { getCartToSend, getPlayerLabel } = useCart();
  const { getTotalOrder, getTotalCart } = usePrice();
  const { getDateFromWeekId, getOfferStartEndDates } = useWeekId();

  const gtmEventPageView = () => {
    if (document?.title && window?.location) {
      window.dataLayer.push({
        event: 'pageView',
        page_title: document.title,
        page_url: window.location.pathname,
      });
    }
  };

  const getProductsList = (packagesList, campsData, formatsData) => {
    const list = [];
    const campsList = campsData && Object.values(campsData)?.length ? Object.values(campsData) : [];

    // package = product
    packagesList?.forEach((pack) => {
      // associated camp
      const camp = campsList.find((c) => c.id === pack.campId);
      const formats = formatsData && Object.values(formatsData)?.length ? Object.values(formatsData) : [];

      // calc average camp price
      let averagePrice = null;
      if (pack.offers && pack.otherOffers) {
        let total = 0;
        const allOffers = pack.offers.concat(pack.otherOffers);
        if (allOffers?.length) allOffers.forEach((offer) => { total += offer.campPrice; });
        averagePrice = total ? total / allOffers.length : 0;
      }

      if (camp?.product) {
        list.push({
          name: camp.product.name[2],
          id: camp.product.id,
          price: averagePrice ? Math.round(averagePrice / 100) : null,
          brand: 'Mouratoglou',
          variant: formats?.find((f) => f.id === camp.formatId)?.name[2],
          category: packagesTypes.find((type) => type.id === camp.typeId)?.label,
          quantity: 1,
        });
      }
    });

    return list;
  };

  const gtmEventCheckoutStep = (packagesList, campsData, formats) => {
    // [ legacy gtm event ]
    window.dataLayer.push({
      event: 'CheckoutStep',
      eventCategory: 'Ecommerce',
      eventAction: 'Checkout',
      eventLabel: 'camp',
      ecommerce:
       {
         checkout: {
           actionField: {
             step: '1',
             label: 'camp',
           },
           products: getProductsList(packagesList, campsData, formats),
         },
       },
    });

    stateDispatch({ type: 'SET_DATA_LAYER_DONE', payload: { ...dataLayerDone, checkoutStep: true } });
  };

  const getArrayFromData = (data) => (data && Object.values(data)?.length ? Object.values(data) : []);

  const getProductsFromCart = (cart, campsData, formatsData, extrasData, accommodationsData) => {
    if (cart) {
      let products = [];

      const formats = getArrayFromData(formatsData);
      const extras = getArrayFromData(extrasData);
      const campsList = getArrayFromData(campsData);
      const accommodations = getArrayFromData(accommodationsData);

      cart.articles?.forEach((article) => {
        // camps
        const campArticle = campsList.find((c) => c.product?.id === article.productId);
        if (campArticle?.product) {
          products.push({
            name: campArticle.product.name[2],
            id: campArticle.product.id,
            price: article.averagePrice ? Math.round(article.averagePrice / 100) : null,
            brand: 'Mouratoglou',
            variant: formats?.find((f) => f.id === campArticle.formatId)?.name[2],
            category: packagesTypes.find((type) => type.id === campArticle.typeId)?.label,
            quantity: 1,
          });
        }

        // extras
        const extraArticle = extras.find((c) => c.product?.id === article.productId);
        if (extraArticle) {
          products.push({
            name: extraArticle.product.name[2],
            id: extraArticle.product.id,
            price: article.averagePrice ? Math.round(article.averagePrice) : null,
            brand: 'Mouratoglou',
            category: 'extra',
            quantity: 1,
          });
        }

        // hostings
        const hostingArticle = accommodations.find((c) => c.product?.id === article.productId);
        if (hostingArticle) {
          products.push({
            name: hostingArticle.product.name[2],
            id: hostingArticle.product.id,
            price: article.averagePrice ? Math.round(article.averagePrice) : null,
            brand: 'Mouratoglou',
            category: 'accommodation',
            quantity: 1,
          });
        }
      });

      if (products?.length) {
        // group products by id and set quantity
        products = uniqBy(products, 'id').map((product) => ({
          ...product,
          quantity: products.filter((p) => p.id === product.id)?.length || 1,
        }));
      }

      return products;
    }

    return null;
  };

  const getTaxAmount = (cart, campsData, extrasData, accommodationsData) => {
    let taxAmount = 0;

    if (cart) {
      const extras = getArrayFromData(extrasData);
      const campsList = getArrayFromData(campsData);
      const accommodations = getArrayFromData(accommodationsData);

      cart.articles?.forEach((article) => {
        const campArticle = campsList.find((c) => c.product?.id === article.productId);
        if (campArticle) {
          const taxRate = campArticle.product?.taxRateValue;
          const articleTaxAmount = taxRate && article.averagePrice ? article.averagePrice * (taxRate / 10000) : 0;
          taxAmount += (articleTaxAmount / 100);
        }

        const extraArticle = extras.find((c) => c.product?.id === article.productId);
        if (extraArticle) {
          const taxRate = extraArticle.product?.taxRateValue;
          const articleTaxAmount = taxRate ? article.averagePrice * (taxRate / 10000) : 0;
          taxAmount += articleTaxAmount;
        }

        const hostingArticle = accommodations.find((c) => c.product?.id === article.productId);
        if (hostingArticle) {
          const taxRate = hostingArticle.product?.taxRateValue;
          const articleTaxAmount = taxRate ? article.averagePrice * (taxRate / 10000) : 0;
          taxAmount += articleTaxAmount;
        }
      });
    }

    return taxAmount.toFixed(2);
  };

  const gtmEventPurchase = async (orderAmount, campsData, formats, extras, accommodations) => {
    const cartToSend = await getCartToSend(true);

    // [ legacy gtm event ]
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: orderId,
            revenue: orderAmount ? orderAmount.toFixed(2) : null,
            tax: getTaxAmount(cartToSend, campsData, extras, accommodations),
            coupon: discountCode?.code || null,
          },
          products: getProductsFromCart(cartToSend, campsData, formats, extras, accommodations),
        },
      },
    });
  };

  const gtmEventSearchClick = (searchParams, isFlexibleSearch) => {
    if (!searchParams) return;

    const months = searchParams.weeks?.map((week) => format(getDateFromWeekId(week), 'MMMM'));

    window.dataLayer.push({ new_ecommerce: null });
    window.dataLayer.push({
      event: 'search_engine_homepage',
      new_ecommerce: {
        duration: searchParams.weekend ? 'weekend' : 'week',
        adults: searchParams.players?.adult || 0,
        months: uniq(months),
        children: searchParams.players?.child || 0,
        baby: searchParams.players?.baby || 0,
        flexible: isFlexibleSearch ? 'yes' : 'no',
        weeks: searchParams.weeks,
        accomodation: searchParams.hosting ? 'yes' : 'no',
      },
    });
  };

  const gtmEventViewProduct = ({
    product, minPrice, player, productFormat, productType,
  }) => {
    if (!product) return;

    window.dataLayer.push({ new_ecommerce: null });
    window.dataLayer.push({
      event: 'view_offer',
      new_ecommerce: {
        type: productType.toLowerCase(),
        format: productFormat.toLowerCase(),
        items: [{
          item_id: product.id,
          item_name: product.name?.[getLanguageId('en')],
          item_category: 'package',
          price: parseFloat(minPrice),
          currency: '€',
          player: player || null,
        }],
      },
    });
  };

  const getPackageItems = ({ player }) => {
    let items = [];

    items = player.stage?.offers?.map((offer) => {
      const dates = getOfferStartEndDates({
        weekId: offer.weekId,
        isWeekend: player.stage?.durationId === 2,
        days: player.stage?.durationInDays,
      });

      return {
        item_id: player.stage?.packageId,
        item_name: player.stage?.packageName?.[getLanguageId('en')],
        item_category: 'package',
        price: offer.price > 0 ? offer.price / 100 : null,
        quantity: 1,
        currency: '€',
        starting_date: isDate(dates?.start) ? format(dates.start, 'yyyy-MM-dd') : null,
        ending_date: isDate(dates?.end) ? format(dates.end, 'yyyy-MM-dd') : null,
      };
    });

    return items;
  };

  const getExtraItems = ({ player }) => {
    const items = [];

    const extraType = router?.query?.opt === '2' ? 'otherOptions' : 'options';
    player[extraType]?.forEach((option) => {
      option.quantities?.forEach((week) => {
        const dates = getOfferStartEndDates({
          weekId: week.weekId,
          isWeekend: player.stage?.durationId === 2,
          days: player.stage?.durationInDays,
        });

        items.push({
          item_id: option.id,
          item_name: option.label?.[getLanguageId('en')],
          item_category: 'extra',
          price: week.price * (week.quantity || 1),
          quantity: week.quantity,
          currency: '€',
          starting_date: isDate(dates?.start) ? format(dates.start, 'yyyy-MM-dd') : null,
          ending_date: isDate(dates?.end) ? format(dates.end, 'yyyy-MM-dd') : null,
        });
      });
    });

    return items;
  };

  const getAccommodationItems = () => {
    const items = [];
    if (!hosting?.length) return [];

    const playerOne = players?.[0];

    playerOne.stage?.weeks?.forEach((weekId) => {
      hosting.forEach((accommodation) => {
        const dates = getOfferStartEndDates({
          weekId,
          isWeekend: playerOne.stage?.durationId === 2,
          days: playerOne.stage?.durationInDays,
        });

        const numberOfWeeks = playerOne.stage?.weeks?.length || 1;

        const pricePerWeek = accommodation.price > 0 ? (accommodation.price / numberOfWeeks) : 0;

        items.push({
          item_id: accommodation.id,
          item_name: accommodation.label?.[getLanguageId('en')],
          item_category: 'accommodation',
          price: pricePerWeek * (accommodation.quantity || 1),
          quantity: accommodation.quantity,
          currency: '€',
          starting_date: isDate(dates?.start) ? format(dates.start, 'yyyy-MM-dd') : null,
          ending_date: isDate(dates?.end) ? format(dates.end, 'yyyy-MM-dd') : null,
        });
      });
    });

    return items;
  };

  const gtmEventAddToCart = ({ step }) => {
    const player = players?.find((pl) => pl.id === currentPlayer?.id);

    if ((!player || !currentPlayer) && step !== bookingStep.steps.hosting) return;

    const playerLabel = currentPlayer ? getPlayerLabel({
      typeLabel: currentPlayer.typeLabel,
      typeId: currentPlayer.typeId,
      playerId: currentPlayer.id,
    }) : null;

    let items = [];

    switch (step) {
      case bookingStep.steps.stage: {
        // PACKAGES
        items = getPackageItems({ player });
        break;
      }
      case bookingStep.steps.camp: {
        // CAMP DETAILS PAGE
        items = getPackageItems({ player });
        break;
      }
      case bookingStep.steps.options: {
        // EXTRAS
        items = getExtraItems({ player });
        break;
      }
      case bookingStep.steps.hosting: {
        // ACCOMMODATIONS
        if (hosting?.length) {
          items = getAccommodationItems();
        }
        break;
      }
      default:
        break;
    }

    if (items?.length) {
      window.dataLayer.push({ new_ecommerce: null });
      window.dataLayer.push({
        event: 'add_to_cart',
        new_ecommerce: {
          currency: '€',
          value: items.reduce((sum, item) => sum + (item.price || 0), 0),
          player: playerLabel || null,
          items,
        },
      });
    }
  };

  const getAllCartItems = () => {
    let items = players?.reduce((all, player) => (
      all.concat(
        getPackageItems({ player }),
        getExtraItems({ player }),
      )
    ), []) || [];

    if (hosting?.length) {
      items = items.concat(getAccommodationItems());
    }

    return items;
  };

  const gtmEventCartSummary = async () => {
    const items = getAllCartItems();

    if (items?.length) {
      window.dataLayer.push({ new_ecommerce: null });
      window.dataLayer.push({
        event: 'view_cart',
        new_ecommerce: {
          currency: '€',
          value: getTotalCart(),
          items,
        },
      });
    }
  };

  const gtmEventCampAttribution = () => {
    const items = getAllCartItems();

    if (items?.length) {
      window.dataLayer.push({ new_ecommerce: null });
      window.dataLayer.push({
        event: 'add_camp',
        new_ecommerce: {
          currency: '€',
          value: getTotalCart(),
          items,
        },
      });
    }
  };

  const gtmEventPay = (paymentMethod) => {
    const items = getAllCartItems();

    if (items?.length) {
      window.dataLayer.push({ new_ecommerce: null });
      window.dataLayer.push({
        event: 'add_payment_info',
        new_ecommerce: {
          currency: '€',
          value: getTotalOrder(),
          payment_type: paymentMethod, // 'card', 'paypal', 'credit'
          items,
        },
      });
    }
  };

  const gtmEventNewPurchase = async ({ campsData, extras, accommodations }) => {
    const cartToSend = await getCartToSend(true);
    const items = getAllCartItems();

    if (cartToSend && items?.length) {
      window.dataLayer.push({ new_ecommerce: null });
      window.dataLayer.push({
        event: 'new_purchase',
        new_ecommerce: {
          currency: '€',
          value: getTotalOrder(),
          transaction_id: orderId,
          tax: getTaxAmount(cartToSend, campsData, extras, accommodations),
          items,
        },
      });
    }
  };

  return {
    gtmEventCheckoutStep,
    gtmEventPurchase,
    gtmEventPageView,
    gtmEventSearchClick,
    gtmEventViewProduct,
    gtmEventAddToCart,
    gtmEventCartSummary,
    gtmEventCampAttribution,
    gtmEventPay,
    gtmEventNewPurchase,
  };
};

export default useDataLayer;
