const getLanguageId = (locale) => {
  switch (locale) {
    case 'fr':
      return 1;
    case 'en':
      return 2;
    case 'it':
      return 3;
    case 'de':
      return 4;
    case 'pt':
      return 5;
    case 'es':
      return 6;
    case 'zh':
      return 7;
    default:
      return 2;
  }
};

export default getLanguageId;
