import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import displayPrice from '../../../../src/utils/displayPrice';

// Components
import PlayerOptionCounter from '../../../book/PlayerOptionCounter/PlayerOptionCounter';

import styles from './CartOptions.module.scss';
import TrashItem from '../../../molecules/TrashItem/TrashItem';
import getLanguageId from '../../../../src/utils/i18n/getLanguageId';

function CartOptions({ player, optionsStocks }) {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const getOptionPrice = (option) => {
    let price = 0;
    option.quantities?.forEach((q) => { price += (q.price * q.quantity); });
    return price;
  };

  const getComponent = (options, title, isOther) => (
    <div className={styles.wrapper}>
      <p className={`${styles.options} m-t-10 fs-12 text-c-black-600 m-b-5`}>
        {title}
      </p>
      {options.map((option) => (
        <div key={option.id} className="m-b-10 mobile-m-b-20">
          <div className="flex j-spb m-b-5">
            <p className={`${styles.mobile_order_2} text-c-primary fw-500 p-r-20 lh-1-2 p-b-5 p-t-2`}>
              {option.label[getLanguageId(locale)]}
            </p>
            <p className={`${styles.mobile_order_3} fw-500 m-l-auto ws-nowrap`}>
              {`${displayPrice(getOptionPrice(option))} €`}
            </p>
            {option?.fixedQuantity === 0 ? (
              <TrashItem
                itemId={option.id}
                playerId={player.id}
                type="option"
                className={`
                  ${styles.mobile_order_1}
                  text-c-secondary p-l-15 p-b-5 c-pointer mobile-p-l-0 mobile-p-r-10 mobile-p-b-0 mobile-p-t-2
                `}
              />
            ) : null}
          </div>
          <div className={styles.input}>
            <PlayerOptionCounter
              player={player}
              isOther={isOther}
              option={option}
              optionStocks={optionsStocks?.find((opt) => opt.optionId === option.id)?.optionStocks}
            />
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      {player.options?.length ? getComponent(player.options, t('cart.options'), false) : null}
      {player.otherOptions?.length ? getComponent(player.otherOptions, t('cart.options.other'), true) : null}
    </>
  );
}

CartOptions.propTypes = {
  player: PropTypes.shape({}).isRequired,
  optionsStocks: PropTypes.arrayOf(PropTypes.shape({})),
};

CartOptions.defaultProps = {
  optionsStocks: null,
};

export default CartOptions;
