const steps = {
  stage: 'stage',
  camp: 'camp',
  options: 'options',
  summary: 'summary',
  hosting: 'hosting',
  assignment: 'assignment',
  billing: 'billing',
  payment: 'payment',
};

const route = {
  choice: '/book/choice',
  stage: '/book/choice/stage',
  options: '/book/choice/options',
  summary: '/book/choice/summary',
  hosting: '/book/hosting',
  assignment: '/book/players',
  billing: '/book/payment/billing',
  payment: '/book/payment/pay',
};

const previousStep = {
  stage: null,
  options: 'stage',
  summary: 'options',
  hosting: 'summary',
  assignment: 'hosting',
  billing: 'assignment',
  payment: 'billing',
};

const nextStep = {
  stage: 'options',
  camp: 'options',
  options: 'summary',
  summary: 'hosting',
  hosting: 'assignment',
  assignment: 'billing',
  billing: 'payment',
  payment: 'order',
};

const stepFromPath = {
  '/book/choice/stage': 'stage',
  '/camp/[id]': 'camp',
  '/book/choice/options': 'options',
  '/book/choice/summary': 'summary',
  '/book/hosting': 'hosting',
  '/book/players': 'assignment',
  '/book/payment/billing': 'billing',
  '/book/payment/pay': 'payment',
};

const getRouteWithParams = (path, params) => `${path}?${params}`;

const bookingStep = {
  route,
  previousStep,
  nextStep,
  stepFromPath,
  getRouteWithParams,
  steps,
};

export default bookingStep;
