import React from 'react';
import PropTypes from 'prop-types';

// Icons
import UserIcon from '../../../../public/icons/user.svg';

import styles from './PlayersCounter.module.scss';
import { useAppContext } from '../../../../src/store/AppContext';

function PlayersCounter({ className }) {
  const [{ players, currentPlayer }] = useAppContext();

  const getCompletedPlayer = () => {
    const uncompleted = players.find((p) => !p.completed);
    const last = players[players.length - 1];
    if (uncompleted) return uncompleted.id < 10 ? `0${uncompleted.id}` : uncompleted.id;
    return last.id < 10 ? `0${last.id}` : last.id;
  };

  return (
    players?.length ? (
      <div className={`${styles.counter} ${className || ''} flex a-center`}>
        <UserIcon />
        <p>
          {currentPlayer ? (
            <span>
              {currentPlayer.id < 10 ? `0${currentPlayer.id}` : currentPlayer.id}
            </span>
          ) : (
            <span>{getCompletedPlayer()}</span>
          )}
          <span>
            {players.length < 10 ? `0${players.length}` : players.length}
          </span>
        </p>
      </div>
    ) : null
  );
}

PlayersCounter.propTypes = {
  className: PropTypes.string,
};

PlayersCounter.defaultProps = {
  className: null,
};

export default PlayersCounter;
