import { isEqual } from 'lodash';

const handleCommonPeriods = (players, search, stateDispatch) => {
  // put first player periods in stored search

  if (players?.length && search) {
    const firstPlayer = players.find((p) => p.id === 1);
    if (firstPlayer && firstPlayer.stage) {
      const firstPlayerPeriods = firstPlayer.stage.weeks;
      const searchPeriods = search.weeks;

      // if search periods is not equal to first player periods
      if (firstPlayerPeriods?.length && searchPeriods?.length && !isEqual(firstPlayerPeriods, searchPeriods)) {
        // search weeks = first player weeks
        stateDispatch({
          type: 'SET_SEARCH',
          payload: {
            ...search,
            weeks: firstPlayerPeriods,
            days: firstPlayer.stage.days,
          },
        });
      }
    }
  }
};

export default handleCommonPeriods;
