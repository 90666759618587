import React from 'react';
import PropTypes from 'prop-types';

import styles from './Flag.module.scss';

function Flag({
  children, color, className, lowOpacity, fontSize, larger, noLower, bold, flexCenter,
}) {
  return (
    <span
      className={`
        ${styles.flag} 
        ${styles[color]}
        ${className || ''} 
        ${lowOpacity ? styles.low_opacity : ''}
        ${fontSize || 'fs-12'}
        ${larger ? styles.larger : ''}
        ${noLower ? styles.noLower : ''}
        ${bold ? styles.bold : ''}
        ${flexCenter ? 'flex center' : ''}
      `}
    >
      {children}
    </span>
  );
}

Flag.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    'primary', 'primary-dark', 'orange', 'white-orange', 'grey', 'primaryLight', 'greyLight', 'green', 'secondary',
  ]),
  fontSize: PropTypes.oneOf(['fs-14']),
  className: PropTypes.string,
  lowOpacity: PropTypes.bool,
  larger: PropTypes.bool,
  noLower: PropTypes.bool,
  bold: PropTypes.bool,
  flexCenter: PropTypes.bool,
};

Flag.defaultProps = {
  color: 'primary',
  className: null,
  lowOpacity: false,
  fontSize: null,
  larger: false,
  noLower: false,
  bold: false,
  flexCenter: false,
};

export default Flag;
