// Utils
import MicroModal from 'micromodal';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useAppContext } from '../store/AppContext';
import bookingStep from '../enum/bookingStep';
import getOptionsPath from '../utils/booking/getOptionsPath';
import getStageRouteFromSearch from '../utils/booking/getStageRouteFromSearch';
import hasAccommodationExtras from '../utils/booking/needHostingExtras';
import isLoginPage from '../utils/isLoginPage';
import modalConfig from '../utils/modalConfig';
import handleCommonPeriods from '../utils/periods/handleCommonPeriods';
import useCart from './useCart';
import useDataLayer from './useDataLayer';

// Services
import useAccountService from '../services/accountService';
import packagesService from '../services/packagesService';

const useCartNextStep = () => {
  const router = useRouter();

  const [packages, setPackages] = useState([]);

  const [{
    hosting, search, currentPlayer, players, userProgress,
  }, stateDispatch] = useAppContext();
  const { gtmEventAddToCart } = useDataLayer();

  const { putCart } = useCart();

  useEffect(() => {
    packagesService.getPackages().then((res) => {
      setPackages(res?.data?.packages || []);
    });
  }, []);

  const [,,, { putClientPlayerData }] = useAccountService();

  const goToNextStep = (setIsLoading, setHasError, redirectAfterSkipPlayer) => {
    const currentStep = bookingStep.stepFromPath[router.pathname];
    let nextStep = bookingStep.nextStep[currentStep] || userProgress;

    if (redirectAfterSkipPlayer) {
      nextStep = 'hosting';
      stateDispatch({ type: 'SET_CONTINUE_SKIP_PLAYER', payload: false });
      stateDispatch({ type: 'SET_CONFIRM_SKIP_OPENED', payload: false });
      if (document.querySelector('#modal-skip-player')) MicroModal.close('modal-skip-player');
    }

    gtmEventAddToCart({ step: currentStep });

    if (currentPlayer) {
      // display options with weeks param
      if (nextStep === 'options' && isNil(currentPlayer.options)) {
        router.push(getOptionsPath(1, currentPlayer));
        if (setIsLoading) setIsLoading(false);
        return;
      }

      if (currentStep === 'options' && router.query?.opt === '1') {
        router.push(getOptionsPath(2, currentPlayer));
        if (setIsLoading) setIsLoading(false);
        return;
      }
    }

    if (nextStep === 'options') {
      router.push(getOptionsPath(1, currentPlayer));
      if (setIsLoading) setIsLoading(false);
      return;
    }

    // next player
    if (nextStep === 'hosting' && players.some((player) => !player.completed)) {
      const nextPlayer = players.find((pl) => !pl.completed) || null;
      if (nextPlayer) {
        stateDispatch({ type: 'SET_CURRENT_PLAYER', payload: nextPlayer });
        handleCommonPeriods(players, search, stateDispatch);
        router.push(getStageRouteFromSearch(search));
        if (setIsLoading) setIsLoading(false);
        return;
      }
    }

    // hosting
    if (nextStep === 'hosting') {
      handleCommonPeriods(players, search, stateDispatch);
      if (!hosting) {
        stateDispatch({ type: 'SET_HOSTING', payload: [] });
        stateDispatch({ type: 'SET_EXTRAS_HOSTING', payload: null });
      }

      // check mandatory accommodations from packages
      const selectedPackagesInCart = players?.map((player) => player.stage?.packageId) || [];
      const packageIdsWithMandatoryAcc = packages
        ?.filter((pack) => pack.accommodationMandatory)
        ?.map((pack) => pack.id)
        || [];
      const isAccommodationStepRequired = selectedPackagesInCart?.length
        && selectedPackagesInCart?.some((id) => packageIdsWithMandatoryAcc.includes(id));

      // if hosting = true in search or if selected packages have at least one mandatory accommodation, go to hosting
      if (search.hosting || hosting?.length || isAccommodationStepRequired) {
        if (currentPlayer) stateDispatch({ type: 'SET_CURRENT_PLAYER', payload: null });
        router.push(bookingStep.route.hosting);
        if (setIsLoading) setIsLoading(false);
      } else {
        // if hosting = false in search, ask user
        MicroModal.show('modal-need-hosting', modalConfig);
        if (setIsLoading) setIsLoading(false);
      }
      return;
    }

    // accommodation extras before assignment
    if (nextStep === 'assignment') {
      stateDispatch({ type: 'SET_CURRENT_PLAYER', payload: null });

      // check if hosting has adults participants
      const hostingHasAdults = hosting?.length ? hosting.some((host) => host.participants?.adult?.length > 0) : false;

      // if every player is a child
      if (!hostingHasAdults && hosting?.length) {
        hasAccommodationExtras(players).then((hasExtras) => {
          if (setIsLoading) setIsLoading(false);

          if (hasExtras && document.querySelector('#modal-accommodation-extras') && !isLoginPage(router)) {
            // if there are accommodation extras, show modal to user
            MicroModal.show('modal-accommodation-extras', modalConfig);
          } else {
            // if no extras, go to assignment
            router.push(bookingStep.route[nextStep]);
          }
        });
      } else {
        if (hostingHasAdults) stateDispatch({ type: 'SET_EXTRAS_HOSTING', payload: null });
        router.push(bookingStep.route[nextStep]);
      }
      return;
    }

    // after assignment, push tennis data for players
    if (nextStep === 'billing' || nextStep === 'payment') {
      if (setHasError) setHasError(false);

      const promises = players.map((player) => (
        new Promise((resolve) => {
          if (nextStep === 'billing') {
            putClientPlayerData({
              playerId: player.playerId,
              tennisData: player.tennisData,
              sexId: player.sexId,
            }).then((res) => {
              if (res?.status === 204) {
                resolve();
              } else {
                if (setHasError) setHasError(true);
                if (setIsLoading) setIsLoading(false);
              }
            });
          } else {
            resolve();
          }
        })
      ));

      Promise.all(promises).then(() => {
        // push cart to api
        putCart(nextStep === 'payment').then((res) => {
          if (res?.data) {
            router.push(bookingStep.route[nextStep]);
            stateDispatch({ type: 'SET_ORDER_ID', payload: res.data.orderId || null });
          } else if (res?.response?.data?.code === 'product_outsold') {
            console.error('One product is out of stock');
            if (setHasError) setHasError(true);
            return;
          } else if (setHasError) setHasError(true);
          if (setIsLoading) setIsLoading(false);
        });
      });
      return;
    }

    if (nextStep) router.push(bookingStep.route[nextStep]);
    if (setIsLoading) setIsLoading(false);
  };

  return {
    goToNextStep,
  };
};

export default useCartNextStep;
