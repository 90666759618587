import { useState } from 'react';

// Utils
import { isNil, isNumber } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useAppContext } from '../store/AppContext';
import getFormattedPlayer from '../utils/account/getFormattedPlayer';
import chunkArray from '../utils/chunkArray';
import useWeeksSlots from './useWeeksSlots';

// Services
import useAccountService from '../services/accountService';
import useCartService from '../services/cartService';
import extrasService from '../services/extrasService';
import packagesService from '../services/packagesService';
import getLanguageId from '../utils/i18n/getLanguageId';

const useCart = () => {
  const { t } = useTranslation();

  const [{
    user, players, hosting, extrasHosting, billingContact, companions, discountCode,
  }] = useAppContext();
  const router = useRouter();
  const { locale } = router;

  const { getWeeksBySlotByPlayer } = useWeeksSlots();

  const [,,, { putCartMine }] = useCartService();
  const [,,, { postNewPlayer }] = useAccountService();
  const [,,, { getAccountPlayers }] = useAccountService();

  const [createdPlayers, setCreatedPlayers] = useState([]);

  const articlesList = [];

  const addArticle = (productId, weekId, playerIds, type, label, averagePrice) => {
    articlesList.push({
      productId,
      weekId,
      playerIds,
      type,
      label,
      averagePrice,
    });
  };

  const getParticipantsById = async (participants) => {
    const list = [];

    await Promise.all(
      participants.map(async (participant) => {
        let newPlayer = null;

        if (!participant.includes('comp')) {
          // assigned player
          const playerId = +participant.match(/\d+/)[0];
          const matchingPlayer = players.find((p) => p.id === playerId);
          if (matchingPlayer?.playerId) list.push(matchingPlayer.playerId);
        } else if (companions?.length) {
          // companion
          const matchingCompanion = companions.find((c) => c.id === participant);

          // find existing companion
          if (matchingCompanion?.assigned?.playerId) {
            list.push(matchingCompanion.assigned.playerId);
          }

          // new companion
          if (matchingCompanion?.new) newPlayer = getFormattedPlayer(matchingCompanion.new);
        } else {
          // default player
          list.push(user.properPlayerId);
        }

        // create new companion to get uid
        if (newPlayer) {
          // check if user has already been created previously
          const created = createdPlayers.find((pl) => pl.id === participant);
          if (created?.uid) {
            list.push(created.uid);
          } else {
            // post new player
            const newPlayerResult = await postNewPlayer(newPlayer);
            if (newPlayerResult?.status === 200 && newPlayerResult.data?.uid) {
              list.push(newPlayerResult.data.uid);
              setCreatedPlayers((prev) => ([...prev, { id: participant, uid: newPlayerResult.data.uid }]));
            } else if (newPlayerResult.response?.status === 409) {
              // if player already exists, find existing player and take uid
              const accountPlayers = await getAccountPlayers();
              if (accountPlayers?.data?.players) {
                const found = Object.values(accountPlayers.data.players)
                  ?.find((pl) => pl.firstname === newPlayer.firstname && pl.lastname === newPlayer.lastname);
                if (found?.uid) list.push(found.uid);
              }
            }
          }
        }
      }),
    );

    return list;
  };

  const getParticipantsByType = async (participants) => {
    const list = await getParticipantsById(participants.map((p) => p.id));
    return list;
  };

  const getAveragePrice = (items) => {
    if (!items?.length) {
      return null;
    }

    const total = items.reduce((sum, item) => sum + item.price, 0);
    return total / items.length;
  };

  const getCartToSend = async (sendBillingInfo) => {
    await Promise.all(
      // for each player
      players.map(async (player) => {
        if (player.playerId) {
          // CAMP
          const camp = await packagesService.getCamp(player.stage?.campId);

          if (camp?.data?.product?.id) {
            const slots = getWeeksBySlotByPlayer(player);
            if (slots && Object.values(slots)?.length) {
              Object.values(slots).forEach((slot) => {
                slot.weeks?.forEach((weekId) => {
                  addArticle(
                    camp.data.product.id,
                    weekId,
                    [player.playerId],
                    'camp',
                    player.stage.packageName,
                    getAveragePrice(player.stage.offers),
                  );
                });
              });
            }
          }
          // EXTRAS
          if (player.options?.length) {
            player.options.forEach((option) => {
              option.quantities?.forEach((q) => {
                [...Array(q.quantity).keys()].forEach(() => {
                  addArticle(
                    option.productId,
                    q.weekId,
                    [player.playerId],
                    'extra',
                    option.label,
                    getAveragePrice(option.quantities),
                  );
                });
              });
            });
          }
          // OTHER EXTRAS
          if (player.otherOptions?.length) {
            player.otherOptions.forEach((option) => {
              option.quantities?.forEach((q) => {
                [...Array(q.quantity).keys()].forEach(() => {
                  addArticle(
                    option.productId,
                    q.weekId,
                    [player.playerId],
                    'otherExtra',
                    option.label,
                    getAveragePrice(option.quantities),
                  );
                });
              });
            });
          }
        }
      }),
    );

    // HOSTING
    if (hosting?.length) {
      await Promise.all(
        hosting.map(async (host) => {
          // participants
          let participantsIds = [];
          if (host.participants && Object.values(host.participants)?.length) {
            const adults = await getParticipantsByType(host.participants.adult);
            const children = await getParticipantsByType(host.participants.child);

            participantsIds = adults.concat(children);
          }

          if (host.productId) {
            // for each weeks
            const slots = getWeeksBySlotByPlayer(players[0]);
            if (slots && Object.values(slots)?.length) {
              Object.values(slots).forEach((slot) => {
                slot.weeks?.forEach((weekId) => {
                  // split participants into arrays to match hosting capacity
                  const splitParticipants = chunkArray(participantsIds, host.capacity);

                  if (splitParticipants?.length) {
                    splitParticipants.forEach((participants) => {
                      addArticle(
                        host.productId,
                        weekId,
                        participants?.length ? participants : [user?.properPlayerId],
                        'hosting',
                        host.label,
                        host.price,
                      );
                    });
                  } else {
                    addArticle(
                      host.productId,
                      weekId,
                      [user?.properPlayerId],
                      'hosting',
                      host.label,
                      host.price,
                    );
                  }
                });
              });
            }
          }
        }),
      );
    }

    // HOSTING EXTRAS (pick-up & drop-off)
    if (extrasHosting?.length) {
      const allExtrasRes = await extrasService.getExtras();
      const allExtras = allExtrasRes?.data?.extras;

      if (allExtras && Object.values(allExtras)?.length) {
        await Promise.all(
          extrasHosting.map(async (extra) => {
            if (extra.quantity > 0) {
              let matchingExtra = null;
              if (extra.formType === 'pickup') {
                matchingExtra = Object.values(allExtras)
                  .find((ex) => ex.showIfWithAccommodations && ex.requirePickupInfos);
              }
              if (extra.formType === 'dropoff') {
                matchingExtra = Object.values(allExtras)
                  .find((ex) => ex.showIfWithAccommodations && ex.requireDropOffInfos);
              }

              if (matchingExtra?.product?.id && extra.participants?.child?.length) {
                const children = await getParticipantsById(extra.participants.child);
                if (children?.length) {
                  const slots = getWeeksBySlotByPlayer(players[0]);

                  if (slots?.length && slots[0].weeks?.length) {
                    // add pick-up only for first week of stay
                    // add drop-off only for last week of stay
                    const firstWeek = slots[0].weeks[0];
                    const lastWeek = slots[slots.length - 1].weeks[slots[slots.length - 1].weeks.length - 1];

                    children.forEach((child) => {
                      addArticle(
                        matchingExtra.product.id,
                        extra.formType === 'pickup' ? firstWeek : lastWeek,
                        [child],
                        'extraHosting',
                        extra.formType,
                        extra.price,
                      );
                    });
                  }
                }
              }
            }
          }),
        );
      }
    }

    // built cart
    let cartToSend = { articles: articlesList };

    // add billing informations
    if (sendBillingInfo && billingContact) {
      cartToSend = {
        ...cartToSend,
        hasInsurance: billingContact.hasInsurance,
        discountCode: discountCode?.code || null,
        billingAddress: {
          address: billingContact.billingAddress?.address,
          zipCode: billingContact.billingAddress?.zipCode,
          city: billingContact.billingAddress?.city,
          country: billingContact.billingAddress?.country?.label,
          makeClientDefault: billingContact.billingAddress?.makeClientDefault,
        },
      };
    }

    return cartToSend;
  };

  const putCart = async (sendBillingInfo) => {
    if (players?.length) {
      const cartToSend = await getCartToSend(sendBillingInfo);
      const result = await putCartMine(cartToSend, getLanguageId(locale));
      return result;
    }

    return null;
  };

  const getPlayerIndex = ({ playerId, typeId }) => {
    if (isNil(playerId) || isNil(typeId) || !players?.length) return null;

    const playersFromType = players.filter((p) => p.typeId === typeId);
    const playerIndex = playersFromType?.findIndex((p) => p.id === playerId);

    return !isNil(playerIndex) && playerIndex >= 0 ? playerIndex + 1 : null;
  };

  const getPlayerLabel = ({
    typeLabel, typeId, playerId, isCompanion, companionIndex,
  }) => {
    if (isNil(typeLabel)) return '';

    if (isCompanion) {
      return `${t(`package.type.${typeLabel}`)} ${t('hosting.companion').toLowerCase()} ${companionIndex || ''}`;
    }

    const index = getPlayerIndex({ typeId, playerId });
    return typeLabel && !isNil(index) ? `${t(`package.type.${typeLabel}`)} ${index}` : '';
  };

  const handleRedirectIfMissingData = () => {
    // players must have all required data filled in
    if (players?.some(
      (player) => (
        !player.tennisData
        || Object.values(player.tennisData)?.some((data) => isNil(data) || !isNumber(data)))
        || !player.sexId,
    )) {
      router.push('/book/players');
    }
  };

  return {
    putCart,
    getCartToSend,
    getPlayerIndex,
    getPlayerLabel,
    handleRedirectIfMissingData,
  };
};

export default useCart;
