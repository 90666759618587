/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import cn from '../../../src/utils/cn';

function ToggleCheck({
  id, labels, name, isChecked, onValueChange, withHtml, disabled, className, isRequired, size,
}) {
  return (
    <div className={className || ''}>
      <div className="toggle-checkbox">
        {labels?.length > 1 ? (
          withHtml ? (
            <label
              htmlFor={id || name}
              className={cn(['second-label', isChecked ? 'checked' : ''])}
              dangerouslySetInnerHTML={{ __html: labels[0] }}
            />
          ) : (
            <label
              htmlFor={id || name}
              className={cn(['second-label', isChecked ? 'checked' : ''])}
            >
              {labels[0]}
            </label>
          )
        ) : null}
        <label htmlFor={id || name}>
          <input
            type="checkbox"
            id={id || name}
            name={name}
            checked={isChecked}
            onChange={(input) => onValueChange(input.target.checked)}
            disabled={disabled}
          />
          <span className={cn(['slider', labels?.length > 1 ? 'double' : '', size])} />
          {withHtml ? (
            <div
              className={cn([
                labels?.length > 1 ? 'with-second-label' : '',
                isChecked ? 'checked' : '',
              ])}
              dangerouslySetInnerHTML={{
                __html: `
                  ${labels?.[labels.length > 1 ? 1 : 0]}${isRequired ? '<span class="text-c-secondary">*</span>' : ''}
                `,
              }}
            />
          ) : (
            <div
              className={cn([
                labels?.length > 1 ? 'with-second-label' : '',
                !labels?.length ? 'no-label' : '',
                isChecked ? 'checked' : '',
              ])}
            >
              {labels?.[labels.length > 1 ? 1 : 0]}
              {isRequired ? <span className="text-c-secondary">*</span> : null}
            </div>
          )}
        </label>
      </div>
    </div>
  );
}

ToggleCheck.propTypes = {
  id: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
  name: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  onValueChange: PropTypes.func,
  withHtml: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  size: PropTypes.oneOf(['large', '']),
};

ToggleCheck.defaultProps = {
  id: null,
  labels: null,
  isChecked: false,
  onValueChange: () => {},
  withHtml: false,
  disabled: false,
  className: null,
  isRequired: false,
  size: '',
};

export default ToggleCheck;
