import React from 'react';

// Utils
import MicroModal from 'micromodal';
import { useTranslation } from 'next-i18next';
import { useAppContext } from '../../../src/store/AppContext';

// Components
import Button from '../../atoms/Button/Button';
import Modal from '../../organisms/Modal/Modal';

import styles from './WarningFirstPlayer.module.scss';

function WarningFirstPlayer() {
  const { t } = useTranslation();
  const [{ players }] = useAppContext();

  const handleConfirm = () => {
    const modal = document.querySelector('#modal-warning-first-player');
    if (modal) {
      modal.dataset.reset = 'true';
      MicroModal.close('modal-warning-first-player');
    }
  };

  return (
    <Modal id="modal-warning-first-player" title={t('booking.choice.warningDeletion.title')}>
      <p className={`${styles.modal_content} m-t-20 fw-400`}>
        {players?.length > 1 ? t('booking.choice.warningDeletion') : t('booking.choice.warningDeletion.singlePlayer')}
      </p>
      <div className="flex a-center j-spb m-t-30 mobile-d-col">
        <Button
          aria-label="Close modal"
          data-micromodal-close
          classNames="flex-auto m-r-20 mobile-w-100 mobile-m-r-0 mobile-m-b-10"
          text="uppercase"
          color="inverted-secondary"
        >
          {t('common.cancel')}
        </Button>
        <Button
          onClick={handleConfirm}
          classNames="flex-auto mobile-w-100"
          text="uppercase"
        >
          {t('booking.choice.warningDeletion.confirm')}
        </Button>
      </div>
    </Modal>
  );
}

WarningFirstPlayer.propTypes = {};

export default WarningFirstPlayer;
