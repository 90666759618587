const triggerCollapse = (collapsedElement, triggerElement, open, adaptTransition) => {
  if (collapsedElement && triggerElement) {
    if (open) {
      triggerElement.classList.add('active');
    } else {
      triggerElement.classList.remove('active');
    }

    if (adaptTransition) {
      collapsedElement.style.transition = open
        ? `max-height ${collapsedElement.scrollHeight / 700}s ease-out`
        : 'max-height 0.6s ease-out';
    }

    collapsedElement.style.maxHeight = open ? `${collapsedElement.scrollHeight}px` : null;
  }
};

export default triggerCollapse;
