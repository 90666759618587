import { useRouter } from 'next/router';
import { format, isFirstDayOfMonth, isValid } from 'date-fns';

// Locales
import frLocale from 'date-fns/locale/fr';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import itLocale from 'date-fns/locale/it';
import ptLocale from 'date-fns/locale/pt';
import zhLocale from 'date-fns/locale/zh-CN';

const useLocalizedDate = () => {
  const { locale } = useRouter();

  const dateFnsLocaleByLang = {
    fr: frLocale,
    de: deLocale,
    en: enLocale,
    es: esLocale,
    it: itLocale,
    pt: ptLocale,
    zh: zhLocale,
  };

  // 02/04/1997
  const getNumericDate = (date) => (
    date && isValid(date)
      ? format(date, 'P', { locale: (dateFnsLocaleByLang[locale] || enLocale) })
      : ''
  );

  // 02 avril
  const getDayMonthDate = (date, withShortMonth) => {
    let schema = '';
    const monthSchema = withShortMonth === true ? 'MMM' : 'MMMM';

    switch (locale) {
      case 'fr':
        schema = isFirstDayOfMonth(date) ? `do ${monthSchema}` : `dd ${monthSchema}`;
        break;
      case 'de':
        schema = `dd. ${monthSchema}`;
        break;
      case 'en':
        schema = `${monthSchema} do`;
        break;
      case 'es':
        schema = `dd 'de' ${monthSchema}`;
        break;
      case 'it':
        schema = `dd ${monthSchema}`;
        break;
      case 'pt':
        schema = `dd 'de' ${monthSchema}`;
        break;
      case 'zh':
        schema = `${monthSchema} dd`;
        break;
      default:
        schema = `dd ${monthSchema}`;
    }

    return format(date, schema, { locale: (dateFnsLocaleByLang[locale] || enLocale) });
  };

  // 02 avril 1997
  const getFullDate = (date, withShortMonth) => {
    let schema = '';
    const monthSchema = withShortMonth === true ? 'MMM' : 'MMMM';

    switch (locale) {
      case 'fr':
        schema = isFirstDayOfMonth(date) ? `do ${monthSchema} yyyy` : `dd ${monthSchema} yyyy`;
        break;
      case 'de':
        schema = `dd. ${monthSchema} yyyy`;
        break;
      case 'en':
        schema = `${monthSchema} do, yyyy`;
        break;
      case 'es':
        schema = `dd 'de' ${monthSchema} 'de' yyyy`;
        break;
      case 'it':
        schema = `dd ${monthSchema} yyyy`;
        break;
      case 'pt':
        schema = `dd 'de' ${monthSchema} 'de' yyyy`;
        break;
      case 'zh':
        schema = `yyyy ${monthSchema} dd`;
        break;
      default:
        schema = `dd ${monthSchema} yyyy`;
    }

    return (
      format(date, schema, { locale: (dateFnsLocaleByLang[locale] || enLocale) })
    );
  };

  return {
    getNumericDate,
    getDayMonthDate,
    getFullDate,
    dateFnsLocaleByLang,
  };
};

export default useLocalizedDate;
