import React from 'react';
import PropTypes from 'prop-types';

// Hooks
import usePrice from '../../../../src/hooks/usePrice';

import styles from './SummaryPrice.module.scss';
import displayPrice from '../../../../src/utils/displayPrice';

function SummaryPrice({ credits }) {
  const { getDiscountedAmount, getTotalOrder, getTotalAfterCredits } = usePrice();

  const hasDiscount = () => getTotalOrder() !== getDiscountedAmount(getTotalOrder());

  return (
    <div className="flex d-col a-end">
      <p className={`${hasDiscount() ? styles.old_price : ''} fw-700 fs-20 ws-nowrap`}>
        {`${displayPrice(
          hasDiscount() ? getTotalOrder() : getTotalAfterCredits(getTotalOrder(), credits),
        )} €`}
      </p>

      {hasDiscount() ? (
        <p className="fs-20 fw-500 ws-nowrap">
          {`${displayPrice(getTotalAfterCredits(getDiscountedAmount(getTotalOrder()), credits))} €`}
        </p>
      ) : null}
    </div>
  );
}

SummaryPrice.propTypes = {
  credits: PropTypes.number,
};

SummaryPrice.defaultProps = {
  credits: null,
};

export default SummaryPrice;
