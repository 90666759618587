import React from 'react';
import PropTypes from 'prop-types';

// Utils
import cn from '../../../../../src/utils/cn';
import useWeekId from '../../../../../src/hooks/useWeekId';

// Components
import Tooltip from '../../../../atoms/Tooltip/Tooltip';

import styles from './PeriodsList.module.scss';

function PeriodsList({
  offersIds, durationId, className, listLength, days, withBackground, canWrap,
}) {
  const { getOfferDatesFromWeekId } = useWeekId();

  return (
    offersIds.length > listLength ? (
      <div className={cn(['flex a-center', canWrap ? 'wrap' : ''])}>
        <span className={`text-c-primary fw-400 ${className || ''}`}>
          {getOfferDatesFromWeekId({
            weekId: offersIds.sort((a, b) => a - b)[0],
            isWeekend: durationId === 2,
            days,
            withShortMonth: true,
          })}
        </span>
        <div
          data-tip={`<div>
          ${offersIds
            .filter((_, i) => i > 0)
            .sort((a, b) => a - b)
            .map((offer) => `
            <span>
            ${getOfferDatesFromWeekId({
              weekId: offer,
              isWeekend: durationId === 2,
              days,
              withShortMonth: true,
            })}
            </span>
            `)
            .join('<br/>')}
          </div>`}
          data-for="showAllWeeksInCart"
          className={cn([
            styles.more_dates,
            'more-dates',
            canWrap ? 'no-margin' : '',
            withBackground ? styles.with_bg : '',
          ])}
        >
          {`+${withBackground ? '' : ' '}${offersIds.length - 1}`}
          <Tooltip id="showAllWeeksInCart" effect="solid" />
        </div>
      </div>
    ) : (
      offersIds.sort((a, b) => a - b).map((offer, i) => (
        <div
          key={i}
          className={`text-c-primary fw-400 ${className || ''}`}
        >
          {getOfferDatesFromWeekId({
            weekId: offer,
            isWeekend: durationId === 2,
            days,
            withShortMonth: true,
          })}
        </div>
      ))
    )
  );
}

PeriodsList.propTypes = {
  offersIds: PropTypes.arrayOf(PropTypes.number),
  durationId: PropTypes.number,
  className: PropTypes.string,
  listLength: PropTypes.number,
  withBackground: PropTypes.bool,
  canWrap: PropTypes.bool,
};

PeriodsList.defaultProps = {
  offersIds: [],
  durationId: 1,
  className: null,
  listLength: 2,
  withBackground: false,
  canWrap: false,
};

export default PeriodsList;
