import { cloneDeep } from 'lodash';

const chunkArray = (array, itemsPerChunk) => {
  const inputArray = cloneDeep(array);
  const result = inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / itemsPerChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return result;
};

export default chunkArray;
