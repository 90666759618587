import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';

// Utils
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { orderBy } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import cn from '../../../src/utils/cn';
import getLanguageId from '../../../src/utils/i18n/getLanguageId';

// Services
import packagesService from '../../../src/services/packagesService';

// Images
import PinIcon from '../../../public/icons/pin.svg';
import PhoneIcon from '../../../public/icons/phone.svg';
import EmailIcon from '../../../public/icons/email.svg';
import LogoFooter from '../../../public/images/logo_footer.png';
import LogoFooterMobile from '../../../public/images/logo_footer_mobile.png';

import styles from './Footer.module.scss';

function Footer() {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [packages, setPackages] = useState({ adult: [], child: [], baby: [] });

  const getPackagesByType = (list, typeId) => orderBy(
    list
      .filter((pack) => pack.typeId === typeId)
      .filter((pack) => pack.active && !pack.archived)

      // Hide "Summer Tour 1" and "Summer Tour 3" (production ids : 19, 31)
      .filter((pack) => pack.id !== 19 && pack.id !== 31)

      .map((pack) => ({ ordinal: pack.ordinal, id: pack.id, name: pack.name })),
    'ordinal',
    'desc',
  );

  useEffect(() => {
    packagesService.getPackages().then((res) => {
      const result = res?.data?.packages;

      if (result?.length) {
        setPackages(() => ({
          adult: getPackagesByType(result, 2),
          child: getPackagesByType(result, 1),
          baby: getPackagesByType(result, 3),
        }));
      }
    });
  }, []);

  return (
    <div id="footer" className={styles.footer}>
      <div className="max-wrapper">
        <h3 className="uppercase fw-300 title-underline fs-14 mobile-fs-16 m-b-20">{t('footer.contactUs')}</h3>
        {!isMobile ? (
          <h2 className="fs-34 fw-500">{t('footer.learnMore')}</h2>
        ) : null}

        <div className={`${styles.columns} flex mobile-d-col`}>
          <div className={styles.col}>
            <p className={styles.info}>
              <EmailIcon width={16} height={16} />
              <a href="mailto:camps@mouratoglou.com" className="underline">{t('mouratogouInfos.email')}</a>
            </p>
            <p className={styles.info}>
              <PhoneIcon width={16} height={16} />
              <a href="tel:+33483881438">{t('mouratogouInfos.tel')}</a>
            </p>
            <p className={cn([styles.info, 'text-uppercase'])}>
              <PinIcon width={16} height={16} />
              {t('mouratogouInfos.address').replace('<br />', ', ')}
              <br />
              {t('mouratogouInfos.country')}
            </p>
          </div>

          {Object.keys(packages).map((type) => (
            packages[type]?.length ? (
              <div key={type} className={`${styles.col} flex d-col a-center mobile-a-start`}>
                <div>
                  <h3 className={`${styles.links_title} uppercase fw-300`}>
                    {t(`footer.camps.${type}`)}
                  </h3>
                  {packages[type].map((pack, index) => (
                    <Link key={index} href={`/camp/${pack.id}`}>
                      <a className={styles.link}>
                        {pack.name[getLanguageId(locale)]}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
            ) : null
          ))}
        </div>
      </div>

      <div className={cn([styles.bottom_links, 'flex center m-t-30'])}>
        <a
          href={`/documents/cgv/${locale}.pdf`}
          target="_blank"
          rel="noreferrer"
          className={cn([styles.bottom_link, 'fs-14 fw-500 text-c-grey-300'])}
        >
          {t('footer.cgv')}
        </a>
        <Link href="/legals/privacy">
          <a className={cn([styles.bottom_link, 'fs-14 fw-500 text-c-grey-300'])}>{t('footer.privacy')}</a>
        </Link>
      </div>

      <div>
        <div className={styles.logo_bg}>
          <Image src={isMobile ? LogoFooterMobile : LogoFooter} alt="Mouratoglou Logo" objectFit="cover" />
        </div>
      </div>
    </div>
  );
}

Footer.propTypes = {};

export default Footer;
