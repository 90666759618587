import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';

// Utils
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import MicroModal from 'micromodal';
import { isNil } from 'lodash';
import modalConfig from '../../../src/utils/modalConfig';
import { useAppContext } from '../../../src/store/AppContext';
import isLoginPage from '../../../src/utils/isLoginPage';
import isAccountPage from '../../../src/utils/isAccountPage';
import bookingStep from '../../../src/enum/bookingStep';
import cn from '../../../src/utils/cn';

// Services
import questionsService from '../../../src/services/faqService';

// Images
import Logo from '../../../public/images/logo_moura.svg';
import LogoMobile from '../../../public/images/logo_moura_mobile.svg';
import ProfilIcon from '../../../public/icons/profil.svg';
import CartIcon from '../../../public/icons/cart.svg';

// Components
import LangSwitcher from './LangSwitcher/LangSwitcher';

import styles from './Header.module.scss';

const BurgerMenu = dynamic(() => (import('./BurgerMenu/BurgerMenu')), { ssr: false });

function Header({ setCartIsOpened }) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const isLaptop = useMediaQuery({ minWidth: 1200 });
  const router = useRouter();

  const [{
    players, user, userProgress, hosting, extrasHosting, isHeaderFolded,
  }, stateDispatch] = useAppContext();

  const [isHomePage] = useState(router.pathname === '/');
  const [isHelpPage] = useState(router.pathname === '/help');

  const [isSticky, setIsSticky] = useState(false);
  const [canDisplayFaq, setCanDisplayFaq] = useState(false);

  const handleFaqDisplay = () => {
    questionsService.getQuestions({ count: 1 }).then((res) => {
      if (res?.data?.questions?.length) {
        setCanDisplayFaq(true);
      }
    });
  };

  const handleOnScroll = () => {
    setIsSticky(window.scrollY > 70);
  };

  useEffect(() => {
    handleFaqDisplay();

    window.addEventListener('scroll', handleOnScroll, true);
    return () => window.removeEventListener('scroll', handleOnScroll, true);
  }, []);

  useEffect(() => {
    const modalCart = document.querySelector('#modal-cart');
    if (isLaptop && modalCart && modalCart.classList.contains('is-open')) MicroModal.close('modal-cart');
  }, [isLaptop]);

  const handleContactClick = () => {
    if (document.getElementById('footer')) {
      const top = document.getElementById('footer').offsetTop;
      window.scrollTo(0, top);
    } else {
      router.push('/').then(() => {
        if (document.getElementById('footer')) {
          const top = document.getElementById('footer').offsetTop;
          window.scrollTo(0, top);
        }
      });
    }
  };

  const handleMobileCartOpening = () => {
    const isOutsideTunnel = isHomePage || isHelpPage || isLoginPage(router) || isAccountPage(router);
    const isLastSteps = userProgress && (userProgress === 'billing' || userProgress === 'payment');

    if (isOutsideTunnel) {
      // if outside tunnel => redirect to step in tunnel
      if (isLastSteps && bookingStep.route[userProgress]) {
        router.push(bookingStep.route[userProgress]);
      } else {
        // if no progress stored, show regular cart
        MicroModal.show('modal-cart', modalConfig);
      }
    } else if (isLastSteps) {
      // if last step, cart is not editable => trigger read-only summary opening
      stateDispatch({ type: 'SET_TRIGGER_MOBILE_SUMMARY', payload: true });
    } else {
      // else, show regular cart
      MicroModal.show('modal-cart', modalConfig);
    }
  };

  const getCartCount = () => {
    if (!players?.length) return 0;

    const camps = players
      ?.map((player) => player.stage)
      .filter((c) => !isNil(c))
      || [];

    const extras = players
      ?.reduce((all, player) => all.concat(player.options).concat(player.otherOptions), [])
      .filter((ex) => !isNil(ex))
      || [];

    return (
      camps.length
      + extras.length
      + (hosting?.length || 0)
      + (extrasHosting?.length || 0)
    );
  };

  return (
    <header
      className={cn([
        styles.header,
        isHomePage || isHelpPage ? styles.has_transparent_header : '',
        isLoginPage(router) ? styles.is_login : '',
        isSticky ? styles.is_sticky : '',
        'flex a-stretch',
        isHeaderFolded ? styles.is_folded : '',
      ])}
    >
      <div className="max-wrapper flex a-center">
        <BurgerMenu canDisplayFaq={canDisplayFaq} />
        <Link href="/">
          <a className={`${styles.logo} c-pointer ${isLoginPage(router) ? styles.primary : ''}`}>
            <Logo width={155} height={28} />
          </a>
        </Link>
        <Link href="/">
          <a className={`${styles.logo_mobile} text-c-primary c-pointer`}>
            <LogoMobile width={22} height={24} />
          </a>
        </Link>

        <div className="m-l-auto flex a-center">
          {canDisplayFaq && !isTablet ? (
            <Link href="/help">
              <a className={styles.header_entry}>
                {t('header.faq')}
              </a>
            </Link>
          ) : null}

          {!isTablet ? (
            <button
              type="button"
              onClick={handleContactClick}
              className={styles.header_entry}
            >
              {t('header.contactUs')}
            </button>
          ) : null}

          {!isLoginPage(router) && !isTablet ? (
            user ? (
              <Link href="/account/camps">
                <a className={styles.header_entry}>
                  <span className={cn([styles.status_badge, styles.green])} />
                  {t('header.account')}
                </a>
              </Link>
            ) : (
              <Link href="/login">
                <a
                  className={cn([
                    styles.header_entry,
                    isLoginPage(router) ? styles.active : '',
                  ])}
                >
                  {t('header.login')}
                </a>
              </Link>
            )
          ) : null}

          {isLaptop && (isHomePage || isHelpPage || isLoginPage(router) || isAccountPage(router)) ? (
            <button type="button" className={styles.header_entry} onClick={() => setCartIsOpened(true)}>
              {`${t('header.cart')}${getCartCount() ? ` (${getCartCount()})` : ''}`}
            </button>
          ) : null}

          {typeof window !== 'undefined' ? (
            isLaptop ? null : (
              <button
                type="button"
                onClick={handleMobileCartOpening}
                className={`${styles.cart} p-5`}
              >
                <CartIcon />
              </button>
            )
          ) : null}

          {isTablet ? (
            <div>
              <Link href={user ? '/account/camps' : '/login'}>
                <a className={`${styles.account} ${styles.with_badge} flex center text-c-primary`}>
                  <ProfilIcon width={24} height={24} />
                  {user ? (
                    <span className={`${styles.status_badge} ${styles.green}`} />
                  ) : null}
                </a>
              </Link>
            </div>
          ) : null}

          {!isMobile ? <LangSwitcher /> : null}
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {};

export default Header;
