import bookingStep from '../../enum/bookingStep';

const getOptionsPath = (optionsType, currentPlayer) => {
  const weeks = currentPlayer?.stage?.weeks || [];
  const currentPackage = currentPlayer?.stage?.packageId || null;

  return bookingStep.getRouteWithParams(
    bookingStep.route.options,
    `opt=${optionsType}&pack=${currentPackage}&weeks=${weeks}`,
  );
};

export default getOptionsPath;
