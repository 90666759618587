import React, { useState } from 'react';

// Utils
import MicroModal from 'micromodal';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useAppContext } from '../../../src/store/AppContext';
import bookingStep from '../../../src/enum/bookingStep';

// Components
import Button from '../../atoms/Button/Button';
import Modal from '../../organisms/Modal/Modal';

import styles from './NeedHostingModal.module.scss';

function NeedHostingModal() {
  const { t } = useTranslation();
  const router = useRouter();
  const [{ user, currentPlayer, players }, stateDispatch] = useAppContext();

  const [hostingIsLoading, setHostingIsLoading] = useState(false);
  const [skipIsLoading, setSkipIsLoading] = useState(false);

  const resetCurrentPlayer = () => {
    if (currentPlayer) stateDispatch({ type: 'SET_CURRENT_PLAYER', payload: null });
  };

  const closeModal = () => {
    if (document.querySelector('#modal-need-hosting')) MicroModal.close('modal-need-hosting');
  };

  const handleSkipHosting = () => {
    setSkipIsLoading(true);
    resetCurrentPlayer();
    stateDispatch({ type: 'SET_HOSTING', payload: [] });
    stateDispatch({ type: 'SET_EXTRAS_HOSTING', payload: null });
    closeModal();
    router.push(bookingStep.route.assignment);
  };

  const handleNeedHosting = () => {
    setHostingIsLoading(true);
    resetCurrentPlayer();
    closeModal();
    router.push(bookingStep.route.hosting);
  };

  return (
    <Modal
      id="modal-need-hosting"
      title={players?.length > 1 ? t('hosting.modal.title') : t('hosting.modal.title.single')}
    >
      <p className={`${styles.modal_content} m-t-20 fw-400`}>
        {players?.length > 1 ? t('hosting.modal.text') : t('hosting.modal.text.single')}
      </p>
      <div className="flex a-center j-spb m-t-30 mobile-d-col">
        <Button
          onClick={handleSkipHosting}
          classNames="flex-auto m-r-20 mobile-w-100 mobile-m-r-0 mobile-m-b-10"
          text="uppercase"
          color="inverted-secondary"
          isLoading={skipIsLoading}
          disabled={hostingIsLoading || skipIsLoading}
        >
          {!user ? `${t('hosting.modal.no')} *` : t('hosting.modal.no')}
        </Button>
        <Button
          onClick={handleNeedHosting}
          classNames="flex-auto mobile-w-100"
          text="uppercase"
          isLoading={hostingIsLoading}
          disabled={hostingIsLoading || skipIsLoading}
        >
          {t('hosting.modal.yes')}
        </Button>
      </div>

      {!user ? (
        <p className="text-c-black-600 fs-12 m-t-20 lh-1-2">
          {`* ${t('booking.finalize.login')}`}
        </p>
      ) : null}
    </Modal>
  );
}

NeedHostingModal.propTypes = {};

export default NeedHostingModal;
