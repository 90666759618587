import { cloneDeep } from 'lodash';

const updateCurrentPlayer = (players, updatedPlayer, stateDispatch, preventClone) => {
  const updatedPlayers = preventClone ? players : cloneDeep(players);
  const index = updatedPlayers.findIndex((player) => player.id === updatedPlayer.id);
  if (index > -1) {
    updatedPlayers[index] = updatedPlayer;
    stateDispatch({ type: 'SET_PLAYERS', payload: updatedPlayers });
  }
};

export default updateCurrentPlayer;
