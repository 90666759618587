import React, { useState } from 'react';

// Utils
import MicroModal from 'micromodal';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useAppContext } from '../../../src/store/AppContext';
import bookingStep from '../../../src/enum/bookingStep';

// Components
import Modal from '../../organisms/Modal/Modal';
import Button from '../../atoms/Button/Button';

import styles from './ResetSearchModal.module.scss';

function ResetSearchModal() {
  const { t } = useTranslation();
  const router = useRouter();
  const [{ userProgress }, stateDispatch] = useAppContext();

  const [resetIsLoading, setResetIsLoading] = useState(false);
  const [cartIsLoading, setCartIsLoading] = useState(false);

  const handleResetSearch = () => {
    const modal = document.querySelector('#modal-reset-search');
    if (modal) {
      setResetIsLoading(true);
      modal.dataset.reset = 'true';
      stateDispatch({ type: 'RESET_BOOKING' });
      MicroModal.close('modal-reset-search');
      setResetIsLoading(false);
    }
  };

  const handleGoToCart = () => {
    if (userProgress && bookingStep.route[userProgress]) {
      setCartIsLoading(true);
      router.push(bookingStep.route[userProgress]);
    }
  };

  return (
    <Modal id="modal-reset-search" title={t('search.reset.title')}>
      <p className={`${styles.modal_content} m-t-20 fw-400`}>
        {t('search.reset.text')}
      </p>
      <div className="flex a-center j-spb m-t-30 mobile-d-col">
        <Button
          onClick={handleGoToCart}
          classNames="flex-auto m-r-20 mobile-w-100 mobile-m-r-0 mobile-m-b-10"
          text="uppercase"
          color="inverted-secondary"
          isLoading={cartIsLoading}
          disabled={resetIsLoading || cartIsLoading}
        >
          {t('search.reset.no')}
        </Button>
        <Button
          onClick={handleResetSearch}
          classNames="flex-auto mobile-w-100"
          text="uppercase"
          isLoading={resetIsLoading}
          disabled={resetIsLoading || cartIsLoading}
        >
          {t('search.reset.yes')}
        </Button>
      </div>
    </Modal>
  );
}

ResetSearchModal.propTypes = {};

export default ResetSearchModal;
