import * as axios from 'axios';

const API_URL = `${process.env.NEXT_PUBLIC_API_URL}api/v1`;

const Axios = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

Axios.interceptors.response.use(
  (response) => response,
  (error) => error,
);

export default Axios;
