import React, { useEffect, useState } from 'react';

// Utils
import { isEqual } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useAppContext } from '../../../../src/store/AppContext';
import useCartNextStep from '../../../../src/hooks/useCartNextStep';
import useCart from '../../../../src/hooks/useCart';

// Components
import Modal from '../../Modal/Modal';
import Button from '../../../atoms/Button/Button';

import styles from './SkipPlayerModal.module.scss';

function SkipPlayerModal() {
  const { t } = useTranslation(['common']);
  const [{
    currentPlayer, players, search, continueAndSkipPlayer, confirmSkipPlayerIsOpened,
  }, stateDispatch] = useAppContext();

  const [isLoading, setIsLoading] = useState(false);

  const [initialPlayers, setInitialPlayers] = useState([]);

  const { goToNextStep } = useCartNextStep();
  const { getPlayerIndex } = useCart();

  const handleSkipPlayer = () => {
    stateDispatch({
      type: 'SET_SEARCH',
      payload: {
        ...search,
        [currentPlayer.typeLabel]: search[currentPlayer.typeLabel] ? search[currentPlayer.typeLabel] - 1 : 0,
      },
    });
    stateDispatch({ type: 'SET_PLAYERS', payload: players.filter((player) => player.id !== currentPlayer.id) });
    stateDispatch({ type: 'SET_CURRENT_PLAYER', payload: null });
  };

  useEffect(() => {
    if (
      continueAndSkipPlayer
      && confirmSkipPlayerIsOpened
      && players?.length
      && initialPlayers?.length
      && !isEqual(initialPlayers, players)) {
      goToNextStep(setIsLoading, () => {}, true);
    }
  }, [players]);

  useEffect(() => {
    setInitialPlayers(players);
  }, []);

  return currentPlayer ? (
    <Modal
      id="modal-skip-player"
      title={t('booking.skipPlayer.cartButton', {
        player: t(`booking.skipPlayer.${currentPlayer.typeLabel}`, {
          index: getPlayerIndex({ typeId: currentPlayer.typeId, playerId: currentPlayer.id }),
        }),
      })}
    >
      <p className={`${styles.modal_content} m-t-20 fw-400`}>
        {t('booking.skipPlayer.confirm.text', {
          player: t(`booking.skipPlayer.${currentPlayer.typeLabel}`, {
            index: getPlayerIndex({ typeId: currentPlayer.typeId, playerId: currentPlayer.id }),
          }),
        })}
        {' '}
        {currentPlayer.id && players.length === currentPlayer.id ? (
          t('booking.skipPlayer.confirm.text.hosting')
        ) : (
          t('booking.skipPlayer.confirm.text.nexPlayer')
        )}
        {' '}
        {t('booking.skipPlayer.confirm.text2', {
          player: t(`booking.skipPlayer.${currentPlayer.typeLabel}`, {
            index: getPlayerIndex({ typeId: currentPlayer.typeId, playerId: currentPlayer.id }),
          }),
        })}
      </p>
      <div className="flex a-center j-spb m-t-30 mobile-d-col">
        <Button
          aria-label="Close modal"
          data-micromodal-close
          text="uppercase"
          classNames="flex-auto m-r-20 mobile-w-100 mobile-m-r-0 mobile-m-b-10"
          color="inverted-secondary"
          isLoading={isLoading}
        >
          {t('common.cancel')}
        </Button>
        <Button
          onClick={handleSkipPlayer}
          classNames="flex-auto mobile-w-100"
          text="uppercase"
        >
          {t('booking.skipPlayer.confirm.yes')}
        </Button>
      </div>
    </Modal>
  ) : null;
}

SkipPlayerModal.propTypes = {};

export default SkipPlayerModal;
