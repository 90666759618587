import React, { useState } from 'react';

// Utils
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { Oval } from 'react-loader-spinner';
import Micromodal from 'micromodal';
import modalConfig from '../../../../src/utils/modalConfig';
import appLanguages from '../../../../src/enum/appLanguages';

// Icons
import ChevronDown from '../../../../public/icons/chevron_down.svg';

import styles from './LangSwitcher.module.scss';

function LangSwitcher() {
  const router = useRouter();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [isLoading, setIsLoading] = useState(false);

  const handleSwitch = (code) => {
    setIsLoading(true);

    router.push(router.asPath, router.asPath, { locale: code }).then(() => {
      setIsLoading(false);
    });
  };

  const getCountryByCode = () => appLanguages.find((c) => c.code === router.locale);

  return (
    !isMobile ? (
      <div className={`${styles.switcher} flex a-center`}>
        {isLoading ? (
          <div className="m-r-20">
            <Oval
              isLoading
              height={20}
              width={20}
              strokeWidth={3}
              color="#A2B0BC"
              secondaryColor="#7E91A1"
              ariaLabel="loading"
            />
          </div>
        ) : null}

        <div className={`${styles.current} flex center`}>
          <span className={styles.flag}>
            {getCountryByCode().flag}
          </span>
          <p className="text-c-back-700 fw-500 fs-14 m-l-15 ws-nowrap">
            {getCountryByCode().label}
          </p>
          <ChevronDown className="m-l-15" style={{ minWidth: '12px' }} />
        </div>
        <div className={styles.panel_wrapper}>
          <div className={styles.panel}>
            {appLanguages.map((country, i) => (
              <button
                key={i}
                className={`${styles.country} flex a-center ${country.code === router.locale ? styles.selected : ''}`}
                onClick={() => handleSwitch(country.code)}
                disabled={isLoading}
              >
                <span className={styles.flag}>{country.flag}</span>
                <span className="text-c-back-700 fw-500 fs-14 m-l-10">
                  {country.label}
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>
    ) : (
      <div className="text-c-black-700 w-100">
        <button
          type="button"
          onClick={() => Micromodal.show('modal-mobile-lang-switcher', modalConfig)}
          className={`${styles.mobile_current} flex a-center w-100`}
        >
          <span className={styles.flag}>{getCountryByCode().flag}</span>
          <span className="flex a-center fw-500 fs-14 m-l-15 m-r-auto">
            {getCountryByCode().label}
            {isLoading ? (
              <div className="m-l-10">
                <Oval
                  isLoading
                  height={16}
                  width={16}
                  strokeWidth={3}
                  color="#A2B0BC"
                  secondaryColor="#7E91A1"
                  ariaLabel="loading"
                />
              </div>
            ) : null}
          </span>
          <ChevronDown className="m-l-10" style={{ minWidth: '12px' }} />
        </button>
      </div>
    )
  );
}

LangSwitcher.propTypes = {};

export default LangSwitcher;
