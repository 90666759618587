import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

// Utils
import { useMediaQuery } from 'react-responsive';
import { useRouter } from 'next/router';
import { isEmpty } from 'lodash';
import { useAppContext } from '../../src/store/AppContext';
import isLoginPage from '../../src/utils/isLoginPage';
import isAccountPage from '../../src/utils/isAccountPage';
import lockBodyScroll from '../../src/utils/lockBodyScroll';
import useDataLayer from '../../src/hooks/useDataLayer';

// Components
import Header from './Header/Header';
import Footer from './Footer/Footer';
import DeleteModal from '../book/DeleteModal/DeleteModal';
import ResetSearchModal from '../book/ResetSearchModal/ResetSearchModal';
import NeedHostingModal from '../book/NeedHostingModal/NeedHostingModal';
import WarningFirstPlayer from '../book/WarningFirstPlayer/WarningFirstPlayer';
import Cart from '../organisms/Cart/Cart';
import CartSummary from '../book/payment/CartSummary/CartSummary';
import SkipPlayerModal from '../organisms/Cart/SkipPlayerModal/SkipPlayerModal';
import NewCartModal from '../organisms/Cart/NextButton/NewCartModal/NewCartModal';

import styles from './Layout.module.scss';

const ReactTooltip = dynamic(() => import('react-tooltip'), { ssr: false });
const MobileCart = dynamic(() => import('../organisms/MobileCart/MobileCart'), { ssr: false });

function Layout({ children }) {
  const router = useRouter();
  const isLaptop = useMediaQuery({ minWidth: 1200 });

  const [{ userProgress }, stateDispatch] = useAppContext();
  const { gtmEventPageView } = useDataLayer();

  const [isMounted, setIsMounted] = useState(false);
  const [isHomePage] = useState(router.pathname === '/');
  const [isHelpPage] = useState(router.pathname === '/help');
  const [cartIsOpened, setCartIsOpened] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      stateDispatch({ type: 'SET_PREVIOUS_PATH', payload: router.pathname });
    };
  }, []);

  useEffect(() => {
    lockBodyScroll(cartIsOpened);
  }, [cartIsOpened]);

  useEffect(() => {
    if (isMounted) {
      let trials = 1;
      const timer = setInterval(() => {
        if (trials === 30 || !isEmpty(document.title)) {
          clearInterval(timer);
          gtmEventPageView();
        } else {
          trials += 1;
        }
      }, 200);
    }
  }, [isMounted]);

  return (
    isMounted && (
      <div className={styles.layout}>
        <Header setCartIsOpened={setCartIsOpened} />

        {children}

        <ReactTooltip
          isCapture
          place="bottom"
          html
          className="tooltip-custom"
          backgroundColor="#E9ECF5"
          textColor="#3F4B96"
        />

        {!isLoginPage(router) && !isAccountPage(router) ? <Footer /> : null}

        {isLaptop && (isHomePage || isHelpPage || isLoginPage(router) || isAccountPage(router)) ? (
          <>
            <button
              type="button"
              aria-label="close"
              className={`${styles.cart_backdrop} ${cartIsOpened ? styles.visible : ''}`}
              onClick={() => setCartIsOpened(false)}
            />

            <div className={`${styles.sticky_cart} ${cartIsOpened ? styles.opened : ''}`}>
              {userProgress && (userProgress === 'billing' || userProgress === 'payment') ? (
                <CartSummary lastStep isOutsideTunnel />
              ) : (
                <Cart setCartIsOpened={setCartIsOpened} isOutsideTunnel />
              )}
            </div>
          </>
        ) : null}

        {!isLaptop ? (
          <MobileCart
            isOutsideTunnel={isHomePage || isHelpPage || isLoginPage(router) || isAccountPage(router)}
          />
        ) : null}

        <DeleteModal />
        <ResetSearchModal />

        {!isHomePage && !isHelpPage && !isLoginPage(router) && !isAccountPage(router) ? (
          <>
            <NeedHostingModal />
            <WarningFirstPlayer />
          </>
        ) : null}

        <SkipPlayerModal />
        <NewCartModal />
      </div>
    )
  );
}

export default Layout;
