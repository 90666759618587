import React from 'react';
import PropTypes from 'prop-types';

// Icons
import InfoIcon from '../../../public/icons/info_i.svg';
import AlertIcon from '../../../public/icons/alert.svg';
import CheckIcon from '../../../public/icons/check.svg';

import styles from './FlashMessage.module.scss';
import cn from '../../../src/utils/cn';

const iconByColor = {
  primary: <InfoIcon />,
  alert: <AlertIcon />,
  error: <AlertIcon />,
  success: <CheckIcon />,
};

function FlashMessage({ message, color }) {
  return (
    <div
      className={`
        ${styles.flash}
        ${color ? styles[color] : ''}
        flex a-center
      `}
    >
      <span className={cn([styles.icon_circle, color ? styles[color] : ''])}>
        {iconByColor[color] || iconByColor.primary}
      </span>
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  );
}

FlashMessage.propTypes = {
  message: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'alert', 'error', 'success']),
};

FlashMessage.defaultProps = {
  message: '',
  color: 'primary',
};

export default FlashMessage;
