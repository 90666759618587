import React from 'react';

// Utils
import MicroModal from 'micromodal';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useAppContext } from '../../../../../src/store/AppContext';

// Components
import Modal from '../../../Modal/Modal';
import Button from '../../../../atoms/Button/Button';

import styles from './NewCartModal.module.scss';

function NewCartModal() {
  const { t } = useTranslation();
  const router = useRouter();
  const [, stateDispatch] = useAppContext();

  const handleNewCart = () => {
    router.push('/').then(() => {
      stateDispatch({ type: 'RESET_BOOKING' });
      MicroModal.close('modal-new-cart');
    });
  };

  return (
    <Modal
      id="modal-new-cart"
      title={t('booking.skipPlayer.cart.newSearch')}
    >
      <p className={`${styles.modal_content} m-t-20 fw-400`}>
        {t('booking.skipPlayer.cart.newSearch.text')}
      </p>
      <div className="flex a-center j-spb m-t-30 mobile-d-col">
        <Button
          aria-label="Close modal"
          data-micromodal-close
          text="uppercase"
          classNames="flex-auto m-r-20 mobile-w-100 mobile-m-r-0 mobile-m-b-10"
          color="inverted-secondary"
        >
          {t('common.cancel')}
        </Button>
        <Button
          onClick={handleNewCart}
          classNames="flex-auto mobile-w-100"
          text="uppercase"
        >
          {t('booking.skipPlayer.confirm.yes')}
        </Button>
      </div>
    </Modal>
  );
}

NewCartModal.propTypes = {};

export default NewCartModal;
