import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'next-i18next';
import { isNil, isNumber } from 'lodash';
import MicroModal from 'micromodal';
import modalConfig from '../../../../src/utils/modalConfig';
import { useAppContext } from '../../../../src/store/AppContext';
import hasAccommodationExtras from '../../../../src/utils/booking/needHostingExtras';
import useCartNextStep from '../../../../src/hooks/useCartNextStep';
import useCart from '../../../../src/hooks/useCart';

// Enum
import bookingStep from '../../../../src/enum/bookingStep';

// Components
import Button from '../../../atoms/Button/Button';
import FlashMessage from '../../../molecules/FlashMessage/FlashMessage';

const NextButton = forwardRef((({
  isOutsideTunnel, billingNextDisabled, className, price,
}, ref) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isLaptop = useMediaQuery({ minWidth: 1200 });
  const router = useRouter();

  const [{
    user, hosting, currentPlayer, players, userProgress, continueAndSkipPlayer,
  }, stateDispatch] = useAppContext();

  const { goToNextStep } = useCartNextStep();
  const { getPlayerIndex } = useCart();

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [nextIsLogin, setNextIsLogin] = useState(false);

  const isDisabled = () => {
    // check that current step has been completed before continuing
    switch (bookingStep.stepFromPath[router.pathname]) {
      // current player needs a camp
      case 'stage':
        return isNil(currentPlayer?.stage);
      // current player needs a camp
      case 'camp':
        return isNil(currentPlayer?.stage);
      case 'options':
        // no requirement
        return false;
      case 'summary':
        // current player must have completed camp and options steps
        return isNil(currentPlayer?.stage) || isNil(currentPlayer?.options);
      case 'hosting':
        // no requirement
        return false;
      case 'assignment':
        // every cart player needs to be assigned to an account player and have all tennis data completed
        return (
          players?.length ? (
            players.some(
              (player) => (
                isNil(player.assignedTo)
                || !player.tennisData
                || Object.values(player.tennisData)?.some((data) => isNil(data) || !isNumber(data)))
                || !player.sexId,
            )
          ) : true
        );
      case 'billing':
        return billingNextDisabled;
      case 'payment':
        return true;
      default:
        return true;
    }
  };

  const handleNextClick = () => {
    setIsLoading(true);
    goToNextStep(setIsLoading, setHasError);
  };

  const isNextPlayerButton = () => (
    router.pathname === bookingStep.route.summary && players?.length > currentPlayer?.id
  );

  const getButtonLabel = () => {
    if (isOutsideTunnel) return t(`cart.goToBooking${isLaptop ? '' : '.mobile'}`);

    if (isNextPlayerButton()) {
      return t('cart.summary.nextPlayer', { count: currentPlayer.id + 1 });
    }

    if (router.pathname === bookingStep.route.billing) {
      return !isMobile ? `${t('payment.form.submit')} *` : `${t('cart.continue')} *`;
    }

    return t('cart.continue');
  };

  const handleNextIsLogin = async () => {
    const nextStep = bookingStep.nextStep[bookingStep.stepFromPath[router.pathname]] || userProgress;

    if (!user && nextStep === 'assignment') {
      const hostingHasAdults = hosting?.length ? hosting.some((host) => host.participants?.adult?.length > 0) : false;

      if (hosting?.length) {
        if (!hostingHasAdults) {
          const hasExtras = await hasAccommodationExtras(players);
          setNextIsLogin(!hasExtras);
        } else {
          setNextIsLogin(true);
        }
      } else {
        setNextIsLogin(true);
      }
    }
  };

  useEffect(() => {
    handleNextIsLogin();
  }, [hosting]);

  const handleConfirmContinueAndSkip = () => {
    stateDispatch({ type: 'SET_CONFIRM_SKIP_OPENED', payload: true });
    MicroModal.show('modal-skip-player', {
      ...modalConfig,
      onClose: () => {
        stateDispatch({ type: 'SET_CONFIRM_SKIP_OPENED', payload: false });
      },
    });
  };

  return (
    <div className="w-100">
      {hasError ? (
        <div className="m-b-10">
          <FlashMessage color="error" message={t('common.error')} />
        </div>
      ) : null}

      <div className={price ? 'flex a-center j-spb w-100' : 'w-100'}>
        {continueAndSkipPlayer && currentPlayer && currentPlayer?.id > 1 ? (
          <Button
            full
            text="uppercase"
            onClick={handleConfirmContinueAndSkip}
          >
            {t('booking.skipPlayer.cartButton', {
              player: t(`booking.skipPlayer.${currentPlayer.typeLabel}`, {
                index: getPlayerIndex({ typeId: currentPlayer.typeId, playerId: currentPlayer.id }),
              }),
            })}
          </Button>
        ) : (
          <>
            {price && !isNextPlayerButton() ? price : null}

            <Button
              full={isLaptop || (!isLaptop && !price) || isNextPlayerButton()}
              text="uppercase"
              disabled={(!isOutsideTunnel && isDisabled()) || isLoading}
              ref={ref}
              onClick={handleNextClick}
              isLoading={isLoading}
              classNames={className}
            >
              {nextIsLogin ? `${getButtonLabel()} *` : getButtonLabel()}
            </Button>
          </>
        )}
      </div>

      {continueAndSkipPlayer && currentPlayer ? (
        <button
          type="button"
          className="fs-16 fw-500 text-c-secondary text-center w-100 p-t-20 m-t-10 p-b-20 c-pointer"
          onClick={() => {
            MicroModal.show('modal-new-cart', { ...modalConfig });
          }}
        >
          {t('booking.skipPlayer.cart.newSearch')}
        </button>
      ) : null}

      {nextIsLogin ? (
        <p className="text-c-black-600 fs-12 m-t-20 lh-1-2">
          {`* ${t('booking.finalize.login')}`}
        </p>
      ) : null}
    </div>
  );
}));

NextButton.displayName = 'NextButton';

NextButton.propTypes = {
  isOutsideTunnel: PropTypes.bool,
  className: PropTypes.string,
  price: PropTypes.node,
};

NextButton.defaultProps = {
  isOutsideTunnel: false,
  className: '',
  price: null,
};

export default NextButton;
