import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { isNil } from 'lodash';

// Icons
import TrashIcon from '../../../public/icons/trash.svg';
import triggerConfirmDelete from '../../../src/utils/booking/triggerConfirmDelete';

function TrashItem({
  itemId, playerId, hostingId, type, className,
}) {
  const handleConfirmTrash = () => {
    if (!isNil(playerId)) triggerConfirmDelete.deleteItemByPlayer(type, itemId, playerId);
    if (!isNil(hostingId)) triggerConfirmDelete.deleteHosting(type, hostingId);
  };

  return (
    <button
      type="button"
      className={className || ''}
      onClick={handleConfirmTrash}
    >
      <TrashIcon />
    </button>
  );
}

TrashItem.propTypes = {
  itemId: PropTypes.number,
  playerId: PropTypes.number,
  hostingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TrashItem.defaultProps = {
  playerId: null,
  hostingId: null,
  itemId: null,
  className: null,
};

export default TrashItem;
