import {
  addDays, differenceInDays, parse, isSameYear,
} from 'date-fns';
import useLocalizedDate from './useLocalizedDate';

const useWeekId = () => {
  const refDate = parse('2018-12-31', 'yyyy-MM-dd', new Date());

  const { getDayMonthDate, getFullDate } = useLocalizedDate();

  const getWeekIdFromDate = (date) => {
    const diff = differenceInDays(date, refDate);
    const weekId = Math.floor(diff / 7) + 1;
    return weekId;
  };

  const getDateFromWeekId = (weekId) => {
    const date = addDays(refDate, (weekId - 1) * 7);
    return date;
  };

  const getDateLabel = (start, end, withShortMonth) => {
    // start
    const hideYear = isSameYear(start, end) || isSameYear(start, new Date());
    const startDateLabel = hideYear ? getDayMonthDate(start, withShortMonth) : getFullDate(start, withShortMonth);

    // end
    const endDateLabel = isSameYear(end, new Date())
      ? getDayMonthDate(end, withShortMonth)
      : getFullDate(end, withShortMonth);

    return `${startDateLabel} - ${endDateLabel}`;
  };

  const getOfferDatesFromWeekId = ({
    weekId, isWeekend, days, withShortMonth,
  }) => {
    if (weekId && days) {
      // if weekend : week starts on saturday, else : week starts on monday
      const start = isWeekend ? addDays(getDateFromWeekId(weekId), 5) : getDateFromWeekId(weekId);
      const end = addDays(start, days - 1);

      return getDateLabel(start, end, withShortMonth);
    }

    return '';
  };

  const getOfferStartEndDates = ({ weekId, isWeekend, days }) => {
    if (weekId && days) {
      // if weekend : week starts on saturday, else : week starts on monday
      const start = isWeekend ? addDays(getDateFromWeekId(weekId), 5) : getDateFromWeekId(weekId);
      const end = addDays(start, days - 1);

      return { start, end };
    }

    return { start: null, end: null };
  };

  return {
    getWeekIdFromDate,
    getDateFromWeekId,
    getOfferDatesFromWeekId,
    getDateLabel,
    getOfferStartEndDates,
  };
};

export default useWeekId;
