/* eslint-disable prefer-destructuring */
import api from './_api';

const getExtras = () => api.get('/extras');
const getExtra = (extraId) => api.get(`/extras/${extraId}`);

const getStocks = (weeks, familyId) => {
  // familyId => 2 : extras / 3 : hostings

  let minWeekId = null;
  let maxWeekId = null;

  if (weeks?.length) {
    const sortedWeeks = weeks.sort((a, b) => a - b);
    minWeekId = sortedWeeks[0];
    maxWeekId = sortedWeeks[sortedWeeks.length - 1] + 1; // WARNING : max week is excluded (+1 to include)

    return api.get(`/productsstocks?familyId=${familyId}&minWeekId=${minWeekId}&maxWeekId=${maxWeekId}`);
  }

  return api.get(`/productsstocks?familyId=${familyId}`);
};

const getExtraPickup = () => api.get('/extras/pick-up');
const getExtraDropoff = () => api.get('/extras/drop-off');

const extrasService = {
  getExtras,
  getExtra,
  getStocks,
  getExtraPickup,
  getExtraDropoff,
};

export default extrasService;
