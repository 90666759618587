import { isArray } from 'lodash';
import bookingStep from '../../enum/bookingStep';

const getStageRouteFromSearch = (search) => {
  let routeWithQuery = `${bookingStep.route.stage}?`;

  // selected weeks
  let weeks = null;
  if (search?.weeks) {
    weeks = isArray(search.weeks)
      ? search.weeks.map((week) => `weeks=${week}`).join('&')
      : `weeks=${search.weeks}`;
  }
  if (weeks) routeWithQuery += `${weeks}&`;

  routeWithQuery += `weekend=${search.weekend ? 'true' : 'false'}&`;
  routeWithQuery += `hosting=${search.hosting ? 'true' : 'false'}&`;

  // players
  routeWithQuery += `baby=${search.baby}&`;
  routeWithQuery += `child=${search.child}&`;
  routeWithQuery += `adult=${search.adult}`;

  return routeWithQuery;
};

export default getStageRouteFromSearch;
