import api from './_api';

const getPackages = () => api.get('/packages?isArchived=false');
const getPackage = (packageId) => api.get(`/packages/${packageId}`);

const getFormats = () => api.get('/formats');
const getTypes = () => api.get('/types');

const getCamp = (campId) => api.get(`/camps/${campId}`);
const getCamps = () => api.get('/camps');

const packagesService = {
  getPackages,
  getPackage,
  getFormats,
  getTypes,
  getCamp,
  getCamps,
};

export default packagesService;
