import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

// Components
import Micromodal from 'micromodal';
import Button from '../../atoms/Button/Button';

import styles from './Modal.module.scss';
import cn from '../../../src/utils/cn';

function Modal({
  id, title, children, mobileButton, mobileNoPadding, rawModal, unclosable, maxWidth, isMobileFullScreen,
}) {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const closeModal = (e) => {
    if (e.target === e.currentTarget) {
      Micromodal.close(id);
    }
  };

  return (
    <div className="modal micromodal-slide" id={id} aria-hidden="true">
      <div role="presentation" className="modal__overlay" onClick={unclosable ? () => {} : closeModal}>
        <div
          className={cn([
            'modal__container',
            rawModal ? 'raw_modal' : '',
            isMobileFullScreen ? 'full_screen' : '',
          ])}
          role="dialog"
          aria-modal="true"
          aria-labelledby={`${id}-title`}
          style={{ maxWidth: maxWidth && !isMobile ? maxWidth : '' }}
        >
          <div
            className={cn([
              styles.container,
              mobileButton ? styles.with_mobile_button : '',
              'modal__inner-container',
              mobileNoPadding ? 'no_padding' : '',
            ])}
          >
            <header className="modal__header">
              {title ? (
                <p className="modal__title" id={`${id}-title`}>
                  {title}
                </p>
              ) : null}
            </header>

            {!unclosable ? (
              <button
                type="button"
                aria-label="close"
                className="modal__close"
                onClick={closeModal}
              />
            ) : null}

            <main className="modal__content flex d-col a-stretch" id={`${id}-title`}>
              {children}
            </main>
            {mobileButton ? (
              <div className={styles.mobile_button}>
                <Button
                  full
                  text="uppercase"
                  aria-label="Close modal"
                  data-micromodal-close
                >
                  {mobileButton}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  mobileButton: PropTypes.string,
  mobileNoPadding: PropTypes.bool,
  rawModal: PropTypes.bool,
  unclosable: PropTypes.bool,
  maxWidth: PropTypes.string,
  isMobileFullScreen: PropTypes.bool,
};

Modal.defaultProps = {
  title: null,
  children: null,
  mobileButton: null,
  mobileNoPadding: false,
  rawModal: false,
  unclosable: false,
  maxWidth: null,
  isMobileFullScreen: false,
};

export default Modal;
