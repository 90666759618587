import '../styles/theme.scss';
import { useEffect, useState } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

// Utils
import { AppProvider } from '../src/store/AppContext';
import lockBodyScroll from '../src/utils/lockBodyScroll';

// Components
import Layout from '../components/layout/Layout';
import PageLoader from '../components/layout/PageLoader/PageLoader';

import '../styles/index.scss';

const GTM_KEY = process.env.NEXT_PUBLIC_GTM_KEY;

function App({ Component, pageProps }) {
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = (url) => setLoading(url !== router.pathname);
    const handleComplete = () => setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);
  }, [router]);

  useEffect(() => {
    lockBodyScroll(loading);
  }, [loading]);

  useEffect(() => {
    if (typeof window !== 'undefined') window.dataLayer = window.dataLayer || [];
  }, []);

  return (
    <AppProvider>
      <Layout key={router.asPath}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Head>

        {GTM_KEY ? (
          <Script
            id="gtm-script"
            dangerouslySetInnerHTML={{
              __html:
              `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://tagging-server.mouratoglou.com/7bg3usup0m5kl53.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GTM_KEY}');`,
            }}
          />
        ) : null}
        <Component {...pageProps} />
      </Layout>
      <PageLoader loading={loading} />
    </AppProvider>
  );
}

export default appWithTranslation(App);
