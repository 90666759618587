import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import triggerCollapse from '../../../../src/utils/collapsibleElement';
import useCart from '../../../../src/hooks/useCart';
import getLanguageId from '../../../../src/utils/i18n/getLanguageId';
import usePrice from '../../../../src/hooks/usePrice';

// Icons
import UserIcon from '../../../../public/icons/user.svg';

// Components
import Flag from '../../../atoms/Flag/Flag';
import PeriodsList from './PeriodsList/PeriodsList';
import displayPrice from '../../../../src/utils/displayPrice';
import CartOptions from '../CartOptions/CartOptions';
import TrashItem from '../../../molecules/TrashItem/TrashItem';

import styles from './PlayerEntry.module.scss';

function PlayerEntry({
  player, isCurrentPlayer, handleScrollCheck, optionsStocks,
}) {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const { getPlayerLabel } = useCart();
  const { getTotalPlayerPrice, getTotalCampPrice } = usePrice();

  const [currentTrigger, setCurrentTrigger] = useState(null);
  const [currentCollapsed, setCurrentCollapsed] = useState(null);

  const closeOthers = () => {
    const collapsibleElements = document.querySelectorAll('.collapsible-players');
    if (collapsibleElements?.length) {
      collapsibleElements.forEach((element) => {
        const trigger = document.querySelector(`#player${element.dataset.playerId}TriggerCollapse`);
        const collapsed = document.querySelector(`#player${element.dataset.playerId}Collapsed`);
        if (+element.dataset.playerId !== player.id) triggerCollapse(collapsed, trigger, false, false);
      });
    }
  };

  const handleCollapse = (open, ajustTransition) => {
    triggerCollapse(currentCollapsed, currentTrigger, open, ajustTransition);
    closeOthers();
    setTimeout(() => { handleScrollCheck(); }, 400);
  };

  useEffect(() => {
    setCurrentTrigger(document.querySelector(`#player${player.id}TriggerCollapse`));
    setCurrentCollapsed(document.querySelector(`#player${player.id}Collapsed`));
  }, []);

  useEffect(() => {
    if (currentTrigger && currentCollapsed && isCurrentPlayer && player?.stage && Object.values(player.stage)?.length) {
      handleCollapse(true, false);
    }
  }, [currentTrigger, currentCollapsed, player]);

  return (
    <div className={styles.player}>
      <button
        id={`player${player.id}TriggerCollapse`}
        type="button"
        data-player-id={player.id}
        className={`
          ${styles.details_opener} 
          ${isCurrentPlayer ? styles.current : ''} 
          flex a-center j-spb w-100 collapsible collapsible-players
          ${player.stage ? 'c-pointer' : ''}
        `}
        onClick={() => handleCollapse(!currentTrigger.classList.contains('active'), false)}
        disabled={!player.stage}
      >
        <p className="flex a-center m-r-auto fw-500 text-c-secondary">
          <UserIcon className="m-r-5" />
          {getPlayerLabel({ typeLabel: player.typeLabel, typeId: player.typeId, playerId: player.id })}
        </p>
        {player.stage ? (
          <span className="fw-700 fs-16">
            {`${displayPrice(getTotalPlayerPrice(player))} €`}
          </span>
        ) : (
          isCurrentPlayer ? (
            <Flag>{t('cart.ongoing')}</Flag>
          ) : null
        )}
      </button>
      <div id={`player${player.id}Collapsed`}>
        {player.stage ? (
          <>
            <p className="m-t-10 fs-12 text-c-black-600 m-b-5">{t('common.camp')}</p>
            <div className="flex j-spb">
              <p className="text-c-primary fw-500 p-r-20 lh-1-2 p-b-5 p-t-2">
                {player.stage.packageName[getLanguageId(locale)]}
              </p>
              <p className="fw-500 m-l-auto ws-nowrap">
                {`${displayPrice(getTotalCampPrice(player.stage))} €`}
              </p>
              <TrashItem
                playerId={player.id}
                type="stage"
                className="text-c-secondary p-l-15 p-b-5 c-pointer"
              />
            </div>
            <div>
              <PeriodsList
                offersIds={player.stage.weeks}
                durationId={player.stage.durationId}
                days={player.stage.durationInDays}
                listLength={player.stage.weeks.length} // no tooltip here, just display all periods
              />
            </div>
          </>
        ) : null}

        <CartOptions
          player={player}
          optionsStocks={optionsStocks}
        />
      </div>
    </div>
  );
}

PlayerEntry.propTypes = {
  player: PropTypes.shape({}),
  isCurrentPlayer: PropTypes.bool,
  handleScrollCheck: PropTypes.func,
  optionsStocks: PropTypes.arrayOf(PropTypes.shape({})),
};

PlayerEntry.defaultProps = {
  player: {},
  isCurrentPlayer: false,
  handleScrollCheck: () => {},
  optionsStocks: null,
};

export default PlayerEntry;
