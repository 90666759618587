// Icons
import FrFlag from '../../public/images/flags/fr.svg';
import EnFlag from '../../public/images/flags/en.svg';
import ItFLag from '../../public/images/flags/it.svg';
import DeFLag from '../../public/images/flags/de.svg';
import PtFLag from '../../public/images/flags/pt.svg';
import EsFLag from '../../public/images/flags/es.svg';
import ZhFLag from '../../public/images/flags/zh.svg';

const appLanguages = [
  { code: 'fr', label: 'FR', flag: <FrFlag /> },
  { code: 'en', label: 'EN', flag: <EnFlag /> },
  { code: 'it', label: 'IT', flag: <ItFLag /> },
  { code: 'de', label: 'DE', flag: <DeFLag /> },
  { code: 'pt', label: 'PT', flag: <PtFLag /> },
  { code: 'es', label: 'ES', flag: <EsFLag /> },
  { code: 'zh', label: 'ZH', flag: <ZhFLag /> },
];

export default appLanguages;
