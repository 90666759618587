import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Oval } from 'react-loader-spinner';
import Logo from '../../../public/images/logo_loader.svg';
import { useAppContext } from '../../../src/store/AppContext';
import lockBodyScroll from '../../../src/utils/lockBodyScroll';

import styles from './PageLoader.module.scss';

function PageLoader({ loading }) {
  const [, stateDispatch] = useAppContext();

  useEffect(() => {
    stateDispatch({ type: 'SET_IS_HEADER_FOLDED', payload: false });
    stateDispatch({ type: 'SET_IS_BOOKING_NEXT_FOLDED', payload: false });
    lockBodyScroll(false);
  }, [loading]);

  return (
    <div className={`${styles.body_loading} ${!loading ? styles.none : ''}`}>
      <div className={styles.img_wrapper}>
        <Logo width={100} />
      </div>
      <div className={styles.loader}>
        <Oval
          isLoading
          height={40}
          width={40}
          strokeWidth={3}
          color="#191a3c"
          secondaryColor="#6875B8"
          ariaLabel="loading"
        />
      </div>
    </div>
  );
}

PageLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default PageLoader;
