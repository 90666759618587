import React, { useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

// Utils
import { useRouter } from 'next/router';
import { isEqual, isNil } from 'lodash';
import setUserBookingProgress from '../utils/booking/setUserBookingProgress';

// Hooks
import useStorage from '../hooks/useStorage';
import bookingStep from '../enum/bookingStep';

// Reducers
import appReducer from './appReducer';

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

const appVersion = 13;

const initStore = {
  // user
  user: null,

  // booking
  search: null,
  currentPlayer: null,
  players: null,
  userProgress: null,
  hosting: null,
  hostingParticipants: [],
  extrasHosting: null,
  billingContact: null,
  freeInsurance: false,
  companions: null,
  discountCode: null,
  orderId: null,
  acceptLegalTerms: false,

  // analytics
  dataLayerDone: {},

  // interface
  cartUpdates: false,
  previousPath: null,
  showTabIndex: null,
  isHeaderFolded: false,
  isBookingNextFolded: false,
  triggerMobileSummary: false,
  confirmSkipPlayerIsOpened: false,
  continueAndSkipPlayer: false,

  // storage lifespan
  createdAt: null,
};

function AppProvider({ children }) {
  const router = useRouter();
  const {
    setItem, getItem, clearAll, clearAllBooking,
  } = useStorage();

  const [state, dispatch] = useReducer((st, act) => appReducer(
    st,
    act,
    setItem,
    initStore,
    clearAll,
    clearAllBooking,
  ), initStore);

  const populateFromStorage = (key, reducerType) => {
    if (!state[key]) {
      const stored = getItem(key);
      if (stored) {
        dispatch({ type: reducerType, payload: stored });
      }
    }
  };

  const handleVersionCheck = () => {
    if (router.pathname === '/payment/[id]') return;

    if (!getItem('appVersion')) {
      dispatch({ type: 'RESET_STATE' });
      setItem('appVersion', appVersion);
    } else if (getItem('appVersion') !== appVersion && typeof window !== 'undefined') {
      if (router.pathname !== '/') {
        router.push('/').then(() => {
          dispatch({ type: 'RESET_STATE' });
          setItem('appVersion', appVersion);
        });
      } else {
        dispatch({ type: 'RESET_STATE' });
        setItem('appVersion', appVersion);
      }
    }
  };

  useEffect(() => {
    // on init populate with local storage
    populateFromStorage('user', 'SET_USER');
    populateFromStorage('search', 'SET_SEARCH');
    populateFromStorage('currentPlayer', 'SET_CURRENT_PLAYER');
    populateFromStorage('players', 'SET_PLAYERS');
    populateFromStorage('userProgress', 'SET_USER_PROGRESS');
    populateFromStorage('hosting', 'SET_HOSTING');
    populateFromStorage('hostingParticipants', 'SET_HOSTING_PARTICIPANTS');
    populateFromStorage('extrasHosting', 'SET_EXTRAS_HOSTING');
    populateFromStorage('billingContact', 'SET_BILLING_CONTACT');
    populateFromStorage('freeInsurance', 'SET_FREE_INSURANCE');
    populateFromStorage('companions', 'SET_COMPANIONS');
    populateFromStorage('discountCode', 'SET_DISCOUNT');
    populateFromStorage('orderId', 'SET_ORDER_ID');
    populateFromStorage('acceptLegalTerms', 'SET_ACCEPT_LEGAL_TERMS');
    populateFromStorage('dataLayerDone', 'SET_DATA_LAYER_DONE');

    handleVersionCheck();
  }, []);

  useEffect(() => {
    if (!state.user && getItem('user')) populateFromStorage('user', 'SET_USER');
  }, [state.user]);

  useEffect(() => {
    const isInsideTunnelOnPlayerSelection = router.pathname.includes(bookingStep.route.choice);
    const isOnCampDetailsPage = bookingStep.stepFromPath[router.pathname] === bookingStep.steps.camp;

    // update current player when list is updated
    if (state.players?.length
      && state.currentPlayer
      && (
        isInsideTunnelOnPlayerSelection || isOnCampDetailsPage
      )
    ) {
      const index = state.players.findIndex((p) => p.id === state.currentPlayer.id);
      if (index > -1) {
        if (!isEqual(state.players[index], state.currentPlayer)) {
          dispatch({ type: 'SET_CURRENT_PLAYER', payload: state.players[index] });
        }
      }
    }
  }, [state.players]);

  useEffect(() => {
    // if current player has camp and options => player is completed
    if (state.currentPlayer
      && !isNil(state.currentPlayer.stage)
      && !isNil(state.currentPlayer.options)
      && !isNil(state.currentPlayer.otherOptions)) {
      const updatedPlayers = [...state.players];
      const playerIndex = updatedPlayers.findIndex((p) => p.id === state.currentPlayer.id);
      updatedPlayers[playerIndex].completed = true;
      dispatch({
        type: 'SET_PLAYERS',
        payload: updatedPlayers,
      });
    }
  }, [state.currentPlayer]);

  useEffect(() => {
    // set the user's booking progress to keep track of the step to return to
    if (state.players) setUserBookingProgress(state, dispatch);
  }, [state.currentPlayer, state.players, state.hosting, state.billingContact, state.acceptLegalTerms]);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAppContext = () => [useContext(AppStateContext), useContext(AppDispatchContext)];

export {
  AppProvider,
  AppStateContext,
  AppDispatchContext,
  useAppContext,
};
