import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// Utils
import { Oval } from 'react-loader-spinner';

import styles from './Button.module.scss';
import cn from '../../../src/utils/cn';

const Button = forwardRef(({
  type,
  children,
  color,
  text,
  full,
  classNames,
  isLoading,
  size,
  ...prop
}, ref) => (
  <button
    className={cn([
      styles.button,
      text ? styles[`text-${text}`] : '',
      full ? styles.full : '',
      classNames || '',
      size ? styles[size] : '',
      color ? styles[color] : '',
    ])}
    type={type}
    {...prop}
    ref={ref}
  >
    {isLoading ? (
      <div className={`${styles.loader} m-r-10`}>
        <Oval
          isLoading
          height={20}
          width={20}
          strokeWidth={3}
          color="#A2B0BC"
          secondaryColor="#C5D0D8"
          ariaLabel="loading"
        />
      </div>
    ) : null}
    {children}
  </button>
));

Button.displayName = 'Button';

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['inverted-secondary', 'inverted-secondary-transparent']),
  text: PropTypes.oneOf(['uppercase']),
  size: PropTypes.oneOf(['small', 'small-bold', 'medium']),
  full: PropTypes.bool,
  classNames: PropTypes.string,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  color: null,
  size: null,
  text: null,
  full: false,
  classNames: null,
  isLoading: false,
};

export default Button;
