import React, { useEffect, useState } from 'react';

// Utils
import { useRouter } from 'next/router';
import { cloneDeep, isNil, omit } from 'lodash';
import { useTranslation } from 'next-i18next';
import MicroModal from 'micromodal';
import { useAppContext } from '../../../src/store/AppContext';
import updateCurrentPlayer from '../../../src/utils/booking/updateCurrentPlayer';
import bookingStep from '../../../src/enum/bookingStep';

// Components
import Modal from '../../organisms/Modal/Modal';
import Button from '../../atoms/Button/Button';

import styles from './DeleteModal.module.scss';
import getStageRouteFromSearch from '../../../src/utils/booking/getStageRouteFromSearch';

// types : stage / option / hebergement / extra hebergement

function DeleteModal() {
  const { t } = useTranslation();
  const router = useRouter();

  const [{
    currentPlayer, players, search, hosting, extrasHosting,
  }, stateDispatch] = useAppContext();

  const [modalType, setModalType] = useState(null);
  const [modalHostingId, setModalHostingId] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const modal = document.querySelector('#modal-confirm-trash');

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && modal.dataset) {
          setModalType(modal.dataset?.type);
          setModalHostingId(modal.dataset?.hostingId);
        }
      });
    });

    if (modal) {
      observer.observe(modal, { attributes: true });
    }

    return () => observer.disconnect();
  }, []);

  const getPlayerById = (pid) => players.find((p) => p.id === pid);

  const handleDeleteOption = (type, option, player, optionWeekId) => {
    const updatedOptions = cloneDeep(player[type]);
    const optIndex = updatedOptions.findIndex((o) => o.id === option.id);

    if (optIndex > -1) {
      if (optionWeekId) {
        if (updatedOptions[optIndex].quantities?.length) {
          const { quantities } = updatedOptions[optIndex];
          const foundWeekIndex = quantities?.length ? quantities.findIndex((q) => q.weekId === +optionWeekId) : -1;
          if (foundWeekIndex > -1) updatedOptions[optIndex].quantities.splice(foundWeekIndex, 1);
        }

        // if no more weeks in option, remove option
        if (updatedOptions[optIndex].quantities?.length === 0) {
          updatedOptions.splice(optIndex, 1);
        }
      } else {
        updatedOptions.splice(optIndex, 1);
      }

      const findPlayer = players.find((p) => p.id === player.id);
      let updatedPlayer = null;

      if (findPlayer) {
        const clonedPlayer = cloneDeep(findPlayer);
        updatedPlayer = { ...clonedPlayer, [type]: updatedOptions };
      } else {
        updatedPlayer = { ...currentPlayer, [type]: updatedOptions };
      }

      if (updatedPlayer) updateCurrentPlayer(players, updatedPlayer, stateDispatch);
      MicroModal.close('modal-confirm-trash');
      setIsLoading(false);
    }
  };

  const handleFindOption = (playerId, optionId, optionWeekId) => {
    const player = getPlayerById(playerId);
    if (player) {
      const option = player.options?.find((o) => o.id === optionId);
      if (isNil(option)) {
        const otherOption = player.otherOptions?.find((o) => o.id === optionId);
        if (!isNil(otherOption)) handleDeleteOption('otherOptions', otherOption, player, optionWeekId);
      } else {
        handleDeleteOption('options', option, player, optionWeekId);
      }
    }
  };

  const blankPlayer = {
    stage: null,
    options: null,
    otherOptions: null,
    completed: false,
    sexId: null,
    tennisData: {
      startYear: '',
      weeklyHours: '',
      yearlyMatches: '',
    },
  };

  const removeAllPlayersChoices = () => {
    const updatedPlayers = [...players].map((player) => ({
      ...omit(player, ['assignedTo']),
      ...blankPlayer,
    }));
    stateDispatch({ type: 'SET_PLAYERS', payload: updatedPlayers });
    stateDispatch({ type: 'SET_HOSTING', payload: null });
    stateDispatch({ type: 'SET_EXTRAS_HOSTING', payload: null });
  };

  const handleDeleteStage = (playerId) => {
    const player = getPlayerById(playerId);

    // if first player camp is removed, remove all players camps
    if (player.id === 1) {
      removeAllPlayersChoices();
    } else {
      // else, just delete selected player
      const updatedPlayer = {
        ...omit(player, ['assignedTo']),
        ...blankPlayer,
      };
      updateCurrentPlayer(players, updatedPlayer, stateDispatch);
    }

    if (router.pathname === bookingStep.route.stage && playerId === currentPlayer?.id) {
      // if already on camp step and same current player
      MicroModal.close('modal-confirm-trash');
      stateDispatch({ type: 'SET_CART_UPDATES', payload: true });
      setIsLoading(false);
    } else {
      // else, set current player to deleted player and go to selection

      if (playerId !== currentPlayer?.id) {
        // set new current player if deleted is not current
        const newCurrentPlayer = players.find((pl) => pl.id === playerId);
        const clonedPlayer = newCurrentPlayer ? cloneDeep(newCurrentPlayer) : null;
        if (clonedPlayer) {
          stateDispatch({
            type: 'SET_CURRENT_PLAYER',
            payload: {
              ...omit(clonedPlayer, ['assignedTo']),
              ...blankPlayer,
            },
          });
        }
      }

      // go back to camp selection
      router.push(getStageRouteFromSearch(search));
      MicroModal.close('modal-confirm-trash');
    }
  };

  const getHostingParticipants = () => {
    const hostingParticipants = hosting?.find((h) => h.id === +(modalHostingId || 0))?.participants;

    if (hostingParticipants && Object.values(hostingParticipants)?.length) {
      const participantsIds = Object.values(hostingParticipants).reduce(
        (par, curr) => par.concat(curr),
        [],
      )?.map((par) => par.id);

      return participantsIds;
    }

    return [];
  };

  const handleRemoveAssociatedExtra = () => {
    const hostingParticipants = getHostingParticipants();
    let updatedExtrasHosting = cloneDeep(extrasHosting);
    updatedExtrasHosting.forEach((_, index) => {
      const participants = updatedExtrasHosting[index]?.participants;
      const updatedAdultParticipants = participants?.adult?.filter(
        (id) => !hostingParticipants.some((pid) => pid === id),
      );
      const updatedChildParticipants = participants?.child?.filter(
        (id) => !hostingParticipants.some((pid) => pid === id),
      );
      updatedExtrasHosting[index].participants.adult = updatedAdultParticipants;
      updatedExtrasHosting[index].participants.child = updatedChildParticipants;
      updatedExtrasHosting[index].quantity = updatedAdultParticipants.concat(updatedChildParticipants)?.length;
    });

    updatedExtrasHosting = updatedExtrasHosting.filter((type) => type.quantity > 0);
    stateDispatch({ type: 'SET_EXTRAS_HOSTING', payload: updatedExtrasHosting });
  };

  const handleDeleteHosting = (hostingId) => {
    const updatedHosting = cloneDeep(hosting || []);
    const index = updatedHosting.findIndex((h) => h.id === +hostingId);
    if (index > -1) {
      // Hosting
      updatedHosting.splice(index, 1);
      stateDispatch({ type: 'SET_HOSTING', payload: updatedHosting });

      if (extrasHosting?.length) {
        handleRemoveAssociatedExtra();
      }
    } else {
      // Hosting extras (pickup and dropoff)
      const extraIndex = extrasHosting.findIndex((ex) => ex.formType === hostingId);
      if (extraIndex > -1) {
        const updatedExtrasHosting = cloneDeep(extrasHosting);
        updatedExtrasHosting.splice(extraIndex, 1);
        stateDispatch({ type: 'SET_EXTRAS_HOSTING', payload: updatedExtrasHosting });
      }
    }

    MicroModal.close('modal-confirm-trash');
    setIsLoading(false);
  };

  const handleDelete = () => {
    setIsLoading(true);
    const modal = document.querySelector('#modal-confirm-trash');

    if (!isNil(modal?.dataset?.type)) {
      switch (modal.dataset.type) {
        case 'option':
          handleFindOption(
            +modal.dataset.playerId,
            +modal.dataset.itemId,
            modal.dataset.optionWeekId || null,
          );
          break;
        case 'stage':
          handleDeleteStage(+modal.dataset.playerId);
          break;
        case 'hosting':
          handleDeleteHosting(modal.dataset.hostingId);
          break;
        case 'extraHosting':
          handleDeleteHosting(modal.dataset.hostingId);
          break;
        default:
          break;
      }
    }
  };

  const handleModalContentText = () => {
    if (modalType === 'hosting' && extrasHosting?.length && modalHostingId) {
      const hostingParticipants = hosting?.find((h) => h.id === +(modalHostingId || 0))?.participants;

      if (hostingParticipants && Object.values(hostingParticipants)?.length) {
        const extraToRemove = extrasHosting.filter(
          (extra) => (
            extra?.participants?.adult.some((id) => getHostingParticipants()?.some((pid) => pid === id))
            || extra?.participants?.child.some((id) => getHostingParticipants()?.some((pid) => pid === id))
            || false
          ),
        );
        const extraHostingTypes = extraToRemove?.map((ex) => ex.formType) || [];

        return t(`cart.delete.hostingAnd${extraHostingTypes?.join('')}`);
      }
      return t(`cart.delete.${modalType}`);
    }
    return t(`cart.delete.${modalType}`);
  };

  return (
    <Modal id="modal-confirm-trash" title={t('cart.delete.title')}>
      {modalType ? (
        <p className={`${styles.modal_content} m-t-20 fw-400`}>
          {handleModalContentText()}
        </p>
      ) : null}
      <div className="flex a-center j-spb m-t-30 mobile-d-col">
        <Button
          aria-label="Close modal"
          data-micromodal-close
          text="uppercase"
          classNames="flex-auto m-r-20 mobile-w-100 mobile-m-r-0 mobile-m-b-10"
          color="inverted-secondary"
          disabled={isLoading}
        >
          {t('common.cancel')}
        </Button>
        <Button
          onClick={handleDelete}
          classNames="flex-auto mobile-w-100"
          text="uppercase"
          isLoading={isLoading}
          disabled={isLoading}
        >
          {t('cart.delete.confirm')}
        </Button>
      </div>
    </Modal>
  );
}

DeleteModal.propTypes = {};

export default DeleteModal;
