import api from './_api';

const getQuestions = ({ count, orderBy }) => api.get(
  `/questions?sorted=${orderBy || 'asc'}${count ? `&count=${count}` : ''}`,
);

const questionsService = {
  getQuestions,
};

export default questionsService;
