import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

// Utils
const ReactTooltip = dynamic(() => import('react-tooltip'), { ssr: false });

function Tooltip({
  id, position, effect, strongIndex,
}) {
  return (
    <ReactTooltip
      id={id}
      isCapture
      place={position}
      html
      className={`tooltip-custom ${strongIndex ? 'strong_index' : ''}`}
      backgroundColor="#E9ECF5"
      textColor="#3F4B96"
      effect={effect}
    />
  );
}

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  position: PropTypes.string,
  effect: PropTypes.oneOf(['solid', 'float']),
  strongIndex: PropTypes.bool,
};

Tooltip.defaultProps = {
  position: 'bottom',
  effect: 'float',
  strongIndex: false,
};

export default Tooltip;
