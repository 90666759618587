import useApi from './useApi';

const useAccountService = () => {
  const {
    requestState, payload, error, api,
  } = useApi();

  const getClientMe = () => api({
    method: 'get',
    url: '/clients/me',
  });

  const getAccountPlayers = () => api({
    method: 'get',
    url: 'players/mine',
  });

  const putClientPlayerData = ({ playerId, tennisData, sexId }) => api({
    method: 'put',
    url: `/clients/own/players/${playerId}/tennis-practice-data`,
    data: {
      ...tennisData,
      sexId,
    },
  });

  const getPlayerThumbnail = (playerId) => api({
    method: 'get',
    url: `/players/${playerId}/thumbnail`,
    responseType: 'blob',
  });

  const putPlayerThumbnail = (playerId, file) => api({
    method: 'put',
    url: `/players/${playerId}/thumbnail`,
    data: file,
  });

  const deletePlayerThumbnail = (playerId) => api({
    method: 'delete',
    url: `/players/${playerId}/thumbnail`,
  });

  const postSharePlayerByEmail = (playerId, email, localeId) => api({
    method: 'post',
    url: `players/${playerId}/link-email`,
    data: {
      email,
      localeId: localeId || 2,
    },
  });

  const postNewPlayer = (player) => api({
    method: 'post',
    url: 'players/mine',
    data: player,
  });

  const putEditPlayer = (player, playerUid) => api({
    method: 'put',
    url: `players/mine/${playerUid}`,
    data: player,
  });

  const putPlayerRightToImage = ({ playerId, isActive }) => api({
    method: 'put',
    url: `clients/own/players/${playerId}:${isActive ? 'activate' : 'deactivate'}-right-to-the-image`,
  });

  const getBookings = () => api({
    method: 'get',
    url: 'bookings/mine',
  });

  const getOrders = () => api({
    method: 'get',
    url: 'orders/mine',
  });

  const getActionsBadges = () => api({
    method: 'get',
    url: 'clients/me/notifications',
  });

  const getInvoice = (invoiceId) => api({
    method: 'get',
    url: `invoice/${invoiceId}`,
    responseType: 'blob',
  });

  const putBillingAddress = (address) => api({
    method: 'put',
    url: 'clients/me/billing-address',
    data: address,
  });

  const putClientsShuttles = (typeId, articleId, formValues) => api({
    method: 'put',
    url: `/clientsshuttles/${typeId}${articleId}`,
    data: formValues,
  });

  const getClientsShuttles = (shuttleId) => api({
    method: 'get',
    url: `/clientsshuttles/${shuttleId}`,
  });

  return [
    requestState,
    payload,
    error,
    {
      getAccountPlayers,
      postSharePlayerByEmail,
      getBookings,
      getOrders,
      getActionsBadges,
      getInvoice,
      putBillingAddress,
      postNewPlayer,
      putEditPlayer,
      putClientsShuttles,
      getClientsShuttles,
      getPlayerThumbnail,
      putPlayerThumbnail,
      deletePlayerThumbnail,
      getClientMe,
      putClientPlayerData,
      putPlayerRightToImage,
    }];
};

export default useAccountService;
