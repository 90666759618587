import MicroModal from 'micromodal';
import modalConfig from '../modalConfig';

const deleteItemByPlayer = (type, itemId, playerId, optionWeekId) => {
  const modal = document.querySelector('#modal-confirm-trash');
  if (modal) {
    modal.dataset.itemId = itemId;
    modal.dataset.type = type;
    modal.dataset.playerId = playerId;

    if (optionWeekId) {
      // delete one week in option
      modal.dataset.optionWeekId = optionWeekId;
    } else {
      // delete all of the option
      modal.removeAttribute('data-option-week-id');
    }

    MicroModal.show('modal-confirm-trash', modalConfig);
  }
};

const deleteHosting = (type, hostingId) => {
  const modal = document.querySelector('#modal-confirm-trash');
  if (modal) {
    modal.dataset.type = type;
    modal.dataset.hostingId = hostingId;
    MicroModal.show('modal-confirm-trash', modalConfig);
  }
};

const triggerConfirmDelete = {
  deleteItemByPlayer,
  deleteHosting,
};

export default triggerConfirmDelete;
