/* eslint-disable no-underscore-dangle */
import { useReducer } from 'react';
import * as axios from 'axios';

import useStorage from '../hooks/useStorage';

const requestReducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST_INIT':
      return {
        ...state,
        requestState: 'loading',
        payload: null,
        error: null,
      };
    case 'REQUEST_SUCCESS':
      return {
        ...state,
        requestState: 'completed',
        payload: action.payload,
        error: null,
      };
    case 'REQUEST_FAILURE':
      return {
        ...state,
        requestState: 'error',
        payload: null,
        error: action.payload,
      };
    default:
      throw new Error(action.payload);
  }
};

const API_URL = `${process.env.NEXT_PUBLIC_API_URL}api/v1`;

const Axios = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

Axios.interceptors.request.use((config) => {
  const { getItem } = useStorage();
  const userToken = getItem('token');

  if (userToken?.token) config.headers['X-Authorization'] = `Bearer ${userToken.token}`;

  return config;
});

Axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    const { setItem, getItem, removeItem } = useStorage();
    const originalRequest = err.config;

    if (err.response
      && err.response.status === 401
      && err.response.data.code !== 'invalid_credentials'
    ) {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        const userToken = getItem('token');

        if (userToken?.refresh_token) {
          try {
            const newUserAuth = await axios({
              method: 'post',
              url: `${API_URL}/auth/token`,
              data: {
                grant_type: 'refresh_token',
                refresh_token: userToken.refresh_token,
              },
            });

            if (newUserAuth.data) {
              setItem('token', {
                token: newUserAuth?.data?.access_token,
                refresh_token: newUserAuth?.data?.refresh_token,
              });
              originalRequest.headers['X-Authorization'] = `Bearer ${newUserAuth.data.access_token}`;

              return Axios(originalRequest);
            }
          } catch (error) {
            removeItem('token');
            removeItem('user');
            window.location.replace('/login');
          }
        } else {
          removeItem('token');
          removeItem('user');
          window.location.replace('/login');
        }
      } else if (originalRequest._retry) {
        removeItem('token');
        removeItem('user');
        window.location.replace('/login');
      }
    }

    return Promise.reject(err);
  },
);

const useApi = () => {
  const [state, dispatch] = useReducer(requestReducer, {
    requestState: 'pending',
    payload: null,
    error: null,
  });

  const responseHandler = (res) => {
    if (res.data) {
      dispatch({ type: 'REQUEST_SUCCESS', payload: res.data });
    }

    if (res.status === 204) {
      dispatch({ type: 'REQUEST_SUCCESS' });
    }

    return res;
  };

  const errorHandler = (resError) => {
    if (resError.response) {
      const status = resError.response ? resError.response.status : null;
      switch (status) {
        case 400:
          dispatch({
            type: 'REQUEST_FAILURE',
            payload: resError.response.data,
          });
          break;
        case 404:
          dispatch({
            type: 'REQUEST_FAILURE',
            payload: resError.response.data,
          });
          break;
        case 401:
          dispatch({
            type: 'REQUEST_FAILURE',
            payload: resError.response.data,
          });
          break;
        case 500: {
          dispatch({
            type: 'REQUEST_FAILURE',
            payload: resError.response.data,
          });
          break;
        }
        case 502: {
          dispatch({
            type: 'REQUEST_FAILURE',
            payload: resError.response.data,
          });
          break;
        }
        case 503: {
          dispatch({
            type: 'REQUEST_FAILURE',
            payload: resError.response.data,
          });
          break;
        }
        default:
          dispatch({
            type: 'REQUEST_FAILURE',
            payload: resError.response.data,
          });
          break;
      }
    }
    return resError;
  };

  const api = (requestConfig) => {
    dispatch({ type: 'REQUEST_INIT' });
    return Axios(requestConfig).then(responseHandler).catch(errorHandler);
  };

  return {
    requestState: state.requestState,
    payload: state.payload,
    error: state.error,
    api,
  };
};

export default useApi;
